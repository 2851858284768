import React from 'react';
import { Grid } from '@mui/material';
import { LaptopMac as LaptopMacIcon, DesktopWindows as DesktopWindowsIcon, Keyboard as KeyboardIcon , Mouse as MouseIcon, Devices as DevicesIcon, Headphones as HeadphonesIcon } from '@mui/icons-material';
import AssignedAssetItems from '../Assets/AssignedAssetItems';

const AssignedAssetsList = ({assetsList}) => {

  const iconMap = {
    Laptop: <LaptopMacIcon fontSize="large" />,
    Monitor: <DesktopWindowsIcon fontSize="large" />,
    Keyboard: <KeyboardIcon fontSize="large" />,
    Mouse: <MouseIcon fontSize="large" />,
    Headphones: <HeadphonesIcon fontSize="large" />
  };

  const assetTypes = [...new Set(assetsList.map(asset => asset.type?.type))];
  
  return (
    <Grid container spacing={1} marginRight={1} marginBottom={6}>
      {assetTypes.map((assetType, index) => {
        const filteredAssets = assetsList.filter(asset => asset.type?.type === assetType);
        
        const icon = iconMap[assetType] || <DevicesIcon fontSize="large" />;   

        return filteredAssets.length > 0 && (
          <Grid item xs={12} sm={6} key={index}>
            <AssignedAssetItems 
              assetTypeLabel={assetType} 
              icon={icon} 
              filteredAssets={filteredAssets} 
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default AssignedAssetsList;
