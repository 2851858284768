const jsonData={
    "items": {
        "DEDUCTION U/S 10": {
            "HOUSE RENT": [
                {
                    "PARTICULARS": "I am staying in a rented house and I agree to submit Rental Receipt and PAN of Landlord where Rent paid is More than 8333/- per Month (Please enter the Rent Amount per year)",
                    "MAXIMUM_LIMIT": "PAN of Land Lord",
                    "DECLARED_AMOUNT": 0
                }
            ]
        },
        "DEDUCTION UNDER CHAPTER VI-A": {
            "DEDUCTION U/S 80C": [
                {
                    "PARTICULARS": "Contribution to Pension Fund (Jeevan Suraksha)",
                    "MAXIMUM_LIMIT": 150000.00,
                    "DECLARED_AMOUNT": 0
                },
                {
                    "PARTICULARS": "Life Insurance Premium on life of self/spouse/child only",
                    "MAXIMUM_LIMIT": 150000.00,
                    "DECLARED_AMOUNT": 0
                },
                {
                    "PARTICULARS": "Deferred Annuity",
                    "MAXIMUM_LIMIT": 150000.00,
                    "DECLARED_AMOUNT": 0
                },
                {
                    "PARTICULARS": "Public Provident Fund in own name/spouse/child only",
                    "MAXIMUM_LIMIT": 150000.00,
                    "DECLARED_AMOUNT": 0
                },
                {
                    "PARTICULARS": "ULIP of UTI/LIC in own name or spouse and child only",
                    "MAXIMUM_LIMIT": 150000.00,
                    "DECLARED_AMOUNT": 0
                },
                {
                    "PARTICULARS": "Repayment of Housing Loan (Only principal)",
                    "MAXIMUM_LIMIT": 150000.00,
                    "DECLARED_AMOUNT": 0
                },
                {
                    "PARTICULARS": "Contribution to Pension Fund or UTI or Notified Mutual Fund",
                    "MAXIMUM_LIMIT": 150000.00,
                    "DECLARED_AMOUNT": 0
                },
                {
                    "PARTICULARS": "Investment in ELSS made in units of Notified Mutual Fund",
                    "MAXIMUM_LIMIT": 150000.00,
                    "DECLARED_AMOUNT": 0
                },
                {
                    "PARTICULARS": "Children Tuition Fee: Restricted to a max of 2 Children",
                    "MAXIMUM_LIMIT": 150000.00,
                    "DECLARED_AMOUNT": 0
                },
                {
                    "PARTICULARS": "Deposit in home loan account scheme of NHB/HDFC",
                    "MAXIMUM_LIMIT": 150000.00,
                    "DECLARED_AMOUNT": 0
                },
                {
                    "PARTICULARS": "5 yrs. Term deposit in a Sch.Bank",
                    "MAXIMUM_LIMIT": 150000.00,
                    "DECLARED_AMOUNT": 0
                },
                {
                    "PARTICULARS": "Others (please specify)",
                    "MAXIMUM_LIMIT": 150000.00,
                    "DECLARED_AMOUNT": 0
                },
                {
                    "PARTICULARS": "Others (please specify)",
                    "MAXIMUM_LIMIT": 150000.00,
                    "DECLARED_AMOUNT": 0
                }
            ],
            "DEDUCTION U/S 80CCC": [
                {
                    "PARTICULARS": "Annuity/Pension Plan",
                    "MAXIMUM_LIMIT": 150000.00,
                    "DECLARED_AMOUNT": 0
                }
            ],
            "DEDUCTION U/S 80CCD": [
                {
                    "PARTICULARS": "Notified Pension Scheme",
                    "MAXIMUM_LIMIT": 150000.00,
                    "DECLARED_AMOUNT": 0
                }
            ],
            "OTHER CHAPTER - VI A DEDUCTIONS": [
                {
                    "PARTICULARS": "Sec 80D - Medical Insurance Premium (If the policy covers a senior citizen then exemption is Rs.50,000/-)",
                    "MAXIMUM_LIMIT": "-",
                    "DECLARED_AMOUNT": 0
                },
                {
                    "PARTICULARS": "Sec 80DD - Handicapped Dependent",
                    "MAXIMUM_LIMIT": "-",
                    "DECLARED_AMOUNT": 0
                },
                {
                    "PARTICULARS": "Sec 80E - Repayment of Loan for higher education (only Interest)",
                    "MAXIMUM_LIMIT": "-",
                    "DECLARED_AMOUNT": 0
                },
                {
                    "PARTICULARS": "Sec 80GG - Rent Paid",
                    "MAXIMUM_LIMIT": "60,000.00",
                    "DECLARED_AMOUNT": 0
                },
                {
                    "PARTICULARS": "Sec 80U - Handicapped",
                    "MAXIMUM_LIMIT": "-",
                    "DECLARED_AMOUNT": 0
                },
                {
                    "PARTICULARS": "Sec 80CCG - Rajeev Gandhi Equity Saving  Scheme(Investment max upto Rs.50,000, would get a deduction of 50% on the amount invested)",
                    "MAXIMUM_LIMIT": "25,000.00",
                    "DECLARED_AMOUNT": 0
                },
                {
                    "PARTICULARS": "Sec 80TTA - Interest on saving a/c",
                    "MAXIMUM_LIMIT": "10,000.00",
                    "DECLARED_AMOUNT": 0
                },
                {
                    "PARTICULARS": "Any other Deduction (Please specify)",
                    "MAXIMUM_LIMIT": "-",
                    "DECLARED_AMOUNT": 0
                }
            ]
        },
        "INTEREST ON HOUSING LOAN": {
            "DEDUCTION U/S 24": [
                {
                    "PARTICULARS": "Interest on Housing Loan on fully constructed accomodation only (Self Occupied)",
                    "MAXIMUM_LIMIT": "2,00,000.00",
                    "DECLARED_AMOUNT": 0
                }
            ]
        }
    }
}
export {jsonData}