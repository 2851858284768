import * as React from 'react';
import { Grid, Box, Button, Checkbox, InputLabel, MenuItem, FormControl, Select, useTheme, CircularProgress, Tooltip } from '@mui/material';
import {Update as UpdateIcon, Restore as RestoreIcon}  from '@mui/icons-material';
import { NotificationManager } from "react-notifications";
import { fetchUserRoles } from '../../services/UserRolesServices';
import { getModulesForPermissions, getModulePermissionByRoleID } from '../../services/ModuleServices';
import { saveUserRolesPermission } from '../../services/userPermissionService';
import './UserPermissions.css'

export default function UserRolePermissions() {
    const theme = useTheme();
    const [userRole, setUserRole] = React.useState('');
    const [userRoles, setUserRoles] = React.useState([]);
    const [modules, setModules] = React.useState([]);

    const [selectedModules, setSelectedModules] = React.useState([]);
    const [initialSelectedModules, setInitialSelectedModules] = React.useState({});
    const [isChanged, setIsChanged] = React.useState(false);
    const [loading, setLoading] = React.useState(true);

    const checkIfChanged = (updatedSelectedModules) => {
        const filteredUpdatedModules = Object.fromEntries(
            Object.entries(updatedSelectedModules).filter(([key, value]) => value === true)
        );
        if (Object.keys(initialSelectedModules).length !== Object.keys(filteredUpdatedModules).length) {
            return true;
        }
        for (const key of Object.keys(initialSelectedModules)) {
            if (filteredUpdatedModules[key] !== initialSelectedModules[key]) {
                return true;
            }
        }
        return false;
    };

    const handleCheckboxChange = (id) => {
        const updatedModules = {
            ...selectedModules,
            [id]: !selectedModules[id],
        };
        setSelectedModules(updatedModules);
        const changed = checkIfChanged(updatedModules);
        setIsChanged(changed);

    };

    const handleChange = (event) => {
        setUserRole(event.target.value);
        setIsChanged(false);
    };

    const getUserroles = async () => {
        const data = await fetchUserRoles('dropdown');
        const sortedData = data.sort((a, b) => a.userRole.localeCompare(b.userRole));
        setUserRole(sortedData[0]._id);
        setUserRoles(sortedData);
    }

    const fetchModules = async () => {
        if (userRole) {
            
            const modulePermissions = await getModulePermissionByRoleID(userRole);
            const modulePermissionsMap = {};
            modulePermissions?.filter(Boolean).forEach(item => {
                modulePermissionsMap[item._id] = true;
              });
            setInitialSelectedModules(modulePermissionsMap);
            setSelectedModules(modulePermissionsMap);
        } else {
            setSelectedModules({})
        }
    }

    const handleSaveChanges = async () => {
        const moduleIds = Object.entries(selectedModules)
            .filter(([moduleId, isSelected]) => isSelected)
            .map(([moduleId]) => moduleId);

        const data = {
            "moduleIds": moduleIds,
            "userRoleId": userRole,
            "activeStatus": true
        }
        const response = await saveUserRolesPermission(data);
        if (response.status == 201) {
            NotificationManager.success("Permissions for Modules have been successfully added ");
            setIsChanged(false);
            setInitialSelectedModules(selectedModules);
        }
        else if (response.status == 200) {
            NotificationManager.success("Permissions for Modules have been successfully updated ");
            setIsChanged(false);
            setInitialSelectedModules(selectedModules);
        }

    }

    React.useEffect(() => {
        const loadData = async () => {
            setLoading(true);
            await getUserroles();
            const data = await getModulesForPermissions();
            setModules(data);
            setLoading(false);
        };
        loadData();
    }, []);

    React.useEffect(() => {
        if (userRole) {
            setLoading(true);
            fetchModules().finally(() => setLoading(false));
        }
    }, [userRole]);

    const handleDiscard = () => {
        setSelectedModules(initialSelectedModules);
        setIsChanged(false);
    }

    if (loading) {
        return <div className='loading-container'><CircularProgress /></div>;
    }

    return (
        <Box className='main-content'>
            <Grid className='grid-container-header grid-container-header-with-nofilters align-header-center'>
                Permissions (Role and Module Mapping)
            </Grid>
            
            <Grid container xs={12} padding={1} className='gridItemStyle'>
                <FormControl sx={{ width: { xs: '100%', md: '40%' }, marginBottom: "1rem" }}>
                    <InputLabel>Select a User Role</InputLabel>
                    <Select
                        value={userRole}
                        label="Select a User Role"
                        onChange={handleChange} >
                        {userRoles.map((row) => (
                            <MenuItem value={row._id}>{row.userRole}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <div>
                    <div>Select the modules accessible to the <strong>{userRoles.filter(x => x._id == userRole).map(x => x.userRole)[0]}</strong></div>
                    <div style={{ display: "flex", flexWrap: "wrap" }}>

                        {modules.map((module) => (
                            <>
                                <Tooltip title={module.description} arrow>
                                    <div key={module._id} style={{ width: "250px", margin: "10px", backgroundColor: theme.palette.secondary.main, borderRadius: "5px"}}>
                                        <Checkbox color="success" checked={!!selectedModules[module._id]}
                                            // disabled={module.name?.toLowerCase() === myProfileModuleName }
                                            onChange={() => handleCheckboxChange(module._id)}/>
                                        {module.name}
                                    </div>
                                </Tooltip>    
                            </>
                        ))}
                    </div>
                    {userRole &&
                        <>
                            <Button variant="contained" startIcon={<RestoreIcon />} disabled={!isChanged} onClick={handleDiscard}>Discard</Button>
                            <Button variant="contained" style={{ textAlign: "right", margin: "10px" }} onClick={handleSaveChanges} startIcon={<UpdateIcon />}
                                disabled={!isChanged} sx={{ marginBottom: { xs: 3, sm: 2 } }}> Save Changes</Button>
                        </>
                    }
                </div>

            </Grid>
        </Box>
    );
}