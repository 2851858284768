import axiosInstance from './axiosInstance';
import { BASE_URL } from '../Constants';

// Service to fetch the latest version
export const fetchLatestVersion = async () => {
    try {
        const response = await axiosInstance.get(`${BASE_URL}/build-version`);
        return response.data;
    } catch (error) {
        console.error('Error fetching the latest version:', error);
        throw error;
    }
};

