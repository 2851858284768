import React, { useEffect, useState , useContext} from 'react';
import { useParams } from 'react-router-dom'
import { Alert, Box, Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, 
         FormControlLabel, FormHelperText, Grid, InputLabel, IconButton, MenuItem, TextField, Typography, Tabs, Tab, Tooltip, 
         Select, useTheme, Divider, LinearProgress } from '@mui/material';
import { getEmployeeById, updateEmployee, deleteFile } from '../../services/employeeService';
import { HighlightOff as HighlightOffIcon, Attachment as AttachmentIcon, AttachFile as AttachFileIcon, Face as FaceIcon, Download as DownloadIcon, Update as UpdateIcon, Cached as CachedIcon, Clear as ClearIcon, Devices as DevicesIcon, DeleteOutline as DeleteOutlineIcon, Cancel as CancelIcon } from '@mui/icons-material';
import { NotificationManager } from 'react-notifications';
import AssignedAssetsList from '../Assets/AssignedAssetsList';
import { format, parseISO } from 'date-fns';
import { useLocation } from 'react-router-dom';
import imageCompression from 'browser-image-compression';
import ImageCrop from '../ImageCrop/ImageCrop';
import { USER_ROLES } from '../../Constants';
import "./updateProfile.css";
import ProfileImageContext from '../../context/ProfileImageContext';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ paddingTop: 0 }}>{children}</Box>}
    </div>
  );
}

export default function UpdateProfile() {
  const theme = useTheme();
  const location = useLocation();
  const tabFromState = location.state?.tabIndex;
  const { id: urlId } = useParams();
  const id = urlId && urlId !== ':id' ? urlId : localStorage.getItem('employeeId');
  // const roleId = localStorage.getItem("roleId");  
  const [tabValue, setTabValue] = useState(0);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [employeeNumber, setEmployeeNumber] = useState('');
  const [emailId, setEmailId] = useState('');
  const [dateOfJoining, setDateOfJoining] = useState('');
  const [designation, setDesignation] = useState('');
  const [uan, setUan] = useState('');
  const [gender, setGender] = useState('');
  const [address, setAddress] = useState('');
  const [qualification, setQualification] = useState('');
  const [phone, setPhone] = useState('');
  const [emergencyContactName, setEmergencyContactName] = useState('');
  const [emergencyContactNumber, setEmergencyContactNumber] = useState('');
  const [aadhaarNumber, setAadhaarNumber] = useState('');
  const [panNumber, setPanNumber] = useState('');
  const [aadhaarFile, setAadhaarFile] = useState(null);
  const [panFile, setPanFile] = useState(null);
  // const [photo, setPhoto] = useState(null);
  const [originalData, setOriginalData] = useState(null);
  const [hasChanges, setHasChanges] = useState(false);
  const [role, setRole] = useState('');
  const [isProfileComplete, setIsProfileComplete] = useState(true);
  const [bloodGroup, setbloodGroup] = useState('');
  const [idCardProvided, setIdCardProvided] = useState(false);
  const [assignedAssets, setAssignedAssets] = useState([])  
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDialogForIdOpen, setIsDialogForIdOpen] = useState(false)
  const [loading, setLoading] = useState(true);
  const [linearLoading, setLinearLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');
  const [errors, setErrors] = useState({ firstName: "", lastName: "", employeeNumber: "", emailId: "", dateOfJoining: "", designation: "", uan: "", gender: "",
    address: "", qualification: "", phone: "", emergencyContactName: "", emergencyContactNumber:"", aadhaarNumber: "", panNumber:"", aadhaarFile: "", panFile: "", bloodGroup:"" 
  });

  const [src, setSrc] = useState(null);
  const [openCropModal, setOpenCropModal] = useState(false);
  const [selectedFileName, setselectedFileName] = useState('');

  const { profileImage, setProfileImage } = useContext(ProfileImageContext);
  
    const validateForm = () => {
      let isValid = true;
      let errors = {};
  
      if (!firstName) {
        errors.firstName = "Employee First name is required";
        isValid = false;
      }
      if (!lastName) {
        errors.lastName = "Employee Last name is required";
        isValid = false;
      }
      if (!gender) {
        errors.gender = "Gender is required";
        isValid = false;
      }
      if (!address) {
        errors.address = "Address is required";
        isValid = false;
      }
      if (!qualification) {
        errors.qualification = "Qualification is required";
        isValid = false;
      }
      if (!phone) {
        errors.phone = "Phone number is required";
        isValid = false;
      }
      if (!emergencyContactName) {
        errors.emergencyContactName = "Emergency Contact Name is required";
        isValid = false;
      }
      if (!emergencyContactNumber) {
        errors.emergencyContactNumber = "Emergency Contact Number is required";
        isValid = false;
      }
      if (!aadhaarNumber) {
        errors.aadhaarNumber = "Aadhaar Number is required";
        isValid = false;
      }
      if (!originalData.aadhaarFile.filename && !aadhaarFile) {
        errors.aadhaarFile = "Upload your Aadhaar";
        isValid = false;
      }
      if (!panNumber) {
        errors.panNumber = "Pan Number is required";
        isValid = false;
      }
      if (!originalData.panFile.filename && !panFile) {
        errors.panFile = "Upload your PAN";
        isValid = false;
      }
      if (!originalData.bloodGroup && !bloodGroup) {
        errors.bloodGroup = "Blood Group is required";
        isValid = false;
      }
  
      setErrors(errors);
      return isValid;
    };

  useEffect(() => {
    fetchEmployee();
    // fetchRoleName();
  },[id]);

  useEffect(() => {
    if (originalData) {
      const isChanged = 
        (firstName && firstName !== originalData.firstName) ||
        (lastName && lastName !== originalData.lastName) ||
        (employeeNumber !== originalData.employeeNumber ) ||
        (emailId !== originalData.emailId) ||
        (dateOfJoining !== formatDate(originalData.dateOfJoining) ) ||
        (designation !== originalData.designation ) ||
        (uan !== originalData.uan ) ||
        (address && address !== originalData.address) ||
        (gender && gender !== originalData.gender) ||
        (address && address !== originalData.address) ||
        (qualification.length && qualification !== originalData.qualification) ||
        (phone && phone !== originalData.phone);
       
      const isModified = 
        (emergencyContactName && emergencyContactName !== originalData.emergencyContactName) ||
        (emergencyContactNumber && emergencyContactNumber !== originalData.emergencyContactNumber) ||
        (bloodGroup && bloodGroup !==originalData.bloodGroup)|| 
        (aadhaarNumber && aadhaarNumber !== originalData.aadhaarNumber) ||
        (panNumber && panNumber !== originalData.panNumber) ||
        (aadhaarFile && aadhaarFile.name !== originalData.aadhaarFile?.filename) ||
        (panFile && panFile.name !== originalData.panFile?.filename);
  
      setHasChanges(isChanged || isModified);
    }
  }, [firstName,lastName, employeeNumber, emailId, dateOfJoining, designation, uan, emergencyContactName, emergencyContactNumber, 
    aadhaarNumber, panNumber, aadhaarFile, panFile, gender, address, qualification, phone, originalData, bloodGroup]);

    useEffect(() => {
      if (tabFromState !== undefined) {
        setTabValue(tabFromState);
      }
    }, [tabFromState]);

    const fetchEmployee = async () => {
    try {
      const response = await getEmployeeById(id);
      if (response) {
        setFirstName(response.firstName || '');
        setLastName(response.lastName || '')
        setEmployeeNumber(response.employeeNumber || '');
        setDateOfJoining(formatDate(response.dateOfJoining || ''));
        setEmailId(response.emailId || '');
        setAadhaarNumber(response.aadhaarNumber || '');
        setPanNumber(response.panNumber || '');
        setEmergencyContactName(response.emergencyContactName || '');
        setEmergencyContactNumber(response.emergencyContactNumber || '');
        setDesignation(response.designation || '');
        setUan(response.uan || '');
        setGender(response.gender || '');
        setAddress(response.address || '');
        setQualification(response.qualification || '');
        setPhone(response.phone || '');
        setOriginalData(response);
        setbloodGroup(response.bloodGroup || '');
        setIdCardProvided(response.idCardProvided || false);
        setAssignedAssets(response.assignedAssetsIds || []);
        // if (response.photo && response.photo.fileId) {
        //   const photoResponse = await getFileById(response.photo.fileId);
        //   setPhoto(photoResponse);
        // }
        const photoUrl = response.photo?.base64Data ? `data:${response.photo.contentType};base64,${response.photo.base64Data}` : null;
      
        // setPhoto(photoUrl);
        setProfileImage(photoUrl);

        //if the employee id (id) and loggedin empid (localStorage.getItem('employeeId')) is same, use the logged in user's role  else use Admin role.
        if(id === localStorage.getItem('employeeId')){
          setRole(response.assignedRoleId.userRole); //This will disable few fields like UAN, email etc fields in case if the logged in user is an EMPLOYEE. 
                                                     //If the Admin has logged in, then it will still enable all the field as the role is set to Admin.
        }
        else{
          setRole(USER_ROLES.ADMIN); // In case if the admin has navigated from Employees screen by clicking "Update Employee", 
                                    // then set the role as admin so that he will be able to edit all the fields like UAN, emailId etc.
        }

        const isComplete = [
          response.firstName,
          response.lastName,
          response.aadhaarNumber,
          response.panNumber,
          response.emergencyContactName,
          response.emergencyContactNumber,
          response.gender,
          response.address,
          response.qualification,
          response.phone,
          response.aadhaarFile,
          response.panFile,
          response.bloodGroup
        ].every(field => field !== undefined && field !== '');
  
        setIsProfileComplete(isComplete);
        setLoading(false);

      } else {
        console.error('No data found in response:', response);
        setIsProfileComplete(false);
        setLoading(false);
      }
    } catch (error) {
      console.error('Failed to fetch employee:', error);
      NotificationManager.error('Failed to fetch employee data.');
      setIsProfileComplete(false);
      setLoading(false);
    }
  };

  const formatDate = (isoDateString) => {
    if (!isoDateString) return '';

    const date = parseISO(isoDateString);
    return format(date, 'yyyy-MM-dd');
  };

  const handleAadhaarFileChange = (setter) => (event) => {
    const file = event.target.files[0];
    if (file && file.size > 250 * 1024) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        aadhaarFile: 'File size should be less than 250KB',
      }));
      setter(null);
    } else {
      errors.aadhaarFile = "";
      setter(file);
    }
    event.target.value = '';
  };

  const handlePanFileChange = (setter) => (event) => {
    const file = event.target.files[0];
    if (file && file.size > 250 * 1024) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        panFile: 'File size should be less than 250KB',
      }));
      setter(null);
    } else {
      errors.panFile = "";
      setter(file);
    }
    event.target.value = '';
  };

  const handleUpdate = async () => {  
    setHasChanges(true);    
    if (linearLoading) return;
    
    if (!firstName || !lastName || !employeeNumber || !emailId || !dateOfJoining || !designation || !uan || 
      !gender || !address || !qualification || !phone || !emergencyContactName || !emergencyContactNumber || 
      !aadhaarNumber || !panNumber || !aadhaarFile || !panFile || !bloodGroup) {
      setErrorMessage('Some of the details are missing. Kindly update the missing details immediately.');
      setHasChanges(false);
    }
    if (!validateForm()) {
      setHasChanges(false);
      return;
    } 
    
    try {
      setLinearLoading(true);
      setErrorMessage('');
      const formData = new FormData();
      formData.append('firstName', firstName.trim());
      formData.append('lastName', lastName.trim());
      formData.append('employeeNumber', employeeNumber); 
      formData.append('emailId', emailId);
      if (dateOfJoining && !isNaN(new Date(dateOfJoining))) {
        formData.append('dateOfJoining', dateOfJoining);
      } else {
        formData.append('dateOfJoining', ''); 
      }
      formData.append('designation', designation);
      formData.append('uan', uan);
      formData.append('gender', gender);
      formData.append('address', address.trim());
      formData.append('qualification', qualification.trim());
      formData.append('phone', phone);
      formData.append('emergencyContactName', emergencyContactName.trim());
      formData.append('emergencyContactNumber', emergencyContactNumber);
      formData.append('bloodGroup', bloodGroup);
      formData.append('aadhaarNumber', aadhaarNumber.trim());
      if (panNumber !== '') {
        formData.append('panNumber', panNumber.trim());
      }
      if (aadhaarFile) formData.append('aadhaarFile', aadhaarFile);
      if (panFile) formData.append('panFile', panFile);

      const response = await updateEmployee(id, formData);
      NotificationManager.success('Your profile details is updated successfully.');
      
      if(!isProfileComplete){
        setTimeout(()=>{
          window.location.reload();
        },[3000]);
      } else {
        setAadhaarFile(null);
        setPanFile(null);
        fetchEmployee();
      }
      
    } catch (error) {
      console.error('Failed to update employee:', error);
      NotificationManager.error(error.response.data.message);
    }finally{
      setLinearLoading(false)
    }
  };

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setselectedFileName(e.target.files[0].name)
      const reader = new FileReader();
      reader.onload = () => {
        setSrc(reader.result);
        setOpenCropModal(true);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
    e.target.value = '';
  };

  const compressImage = async (image) => {
    try {
      if (image.size > 100 * 1024) {
        const options = {
          maxSizeMB: 0.1,
          maxWidthOrHeight: 800,
          useWebWorker: true,
        };
        image = await imageCompression(image, options);
      }
      return image;
    } catch (error) {
      console.error('Image compression failed:', error);
      throw new Error('Image compression failed');
    }
  };

  const uploadImage = async (image) => {
    try {
      // Convert the image to Base64
      const base64Image = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(image);
      });
      const formData = new FormData();
      formData.append('photo', image);
      await updateEmployee(id, formData);
      // setPhoto(base64Image);
      setProfileImage(base64Image);
      NotificationManager.success('Your profile picture is updated successfully');
    } catch (error) {
      console.error('Failed to upload photo:', error);
      NotificationManager.error('Failed to upload your profile picture.');
    }
  };
  
  const setCroppedImageUrlCallback = async (croppedImage) => {
    try {
      let fileToUpload = await compressImage(croppedImage);

      const updatedFile = new File([fileToUpload], selectedFileName, {
        type: fileToUpload.type,
        lastModified: fileToUpload.lastModified
      });
      await uploadImage(updatedFile);
    } 
    catch (error) {
      console.error('Error in image processing:', error);
      NotificationManager.error('An error occurred while processing the image.');
    }
  };
  
  const getInitials = () => {
    return `${firstName.charAt(0)}${lastName.charAt(0)}`;
  };

  const handleRemovePhoto = () => {
    setIsDialogOpen(true);
  };
  
  const handleConfirmDelete = async () => {
    if (originalData?.photo?.fileId) {
      try {
        await deleteFile(id);
        NotificationManager.success('Your profile picture is removed successfully');
        // setPhoto(null);
        setProfileImage();
      } catch (error) {
        console.error('Error removing photo:', error);
      }
    } else {
      setProfileImage();
    }
    setIsDialogOpen(false); 
  };
  
  const handleCancelDelete = () => {
    setIsDialogOpen(false);
  };

  const handleCheckboxChange = async (event) => {
    const newIdCardStatus = event.target.checked;

    if (!newIdCardStatus) {
      setIsDialogForIdOpen(true);
    } else {
      updateIdCardStatus(newIdCardStatus);
    }
  };

  const updateIdCardStatus = async (newStatus) => {
    setIdCardProvided(newStatus);
    try {
      const formData = new FormData();
      formData.append('idCardProvided', newStatus);

      await updateEmployee(id, formData);
      NotificationManager.success('Employee ID card status updated successfully');
    } catch (error) {
      console.error('Failed to update ID card status:', error);
      NotificationManager.error('Failed to update ID card status.');
    }
  };

  const handleDialogClose = (confirm) => {
    setIsDialogForIdOpen(false);
    if (confirm) {
      updateIdCardStatus(false);
    } else {
      setIdCardProvided(true);
    }
  };

  const handleDiscard = () => {
    if (originalData) {
      setFirstName(originalData.firstName || '');
      setLastName(originalData.lastName || '');
      setEmployeeNumber(originalData.employeeNumber || '');
      setDateOfJoining(formatDate(originalData.dateOfJoining || ''));
      setEmailId(originalData.emailId || '');
      setAadhaarNumber(originalData.aadhaarNumber || '');
      setPanNumber(originalData.panNumber || '');
      setEmergencyContactName(originalData.emergencyContactName || '');
      setEmergencyContactNumber(originalData.emergencyContactNumber || '');
      setDesignation(originalData.designation || '');
      setUan(originalData.uan || '');
      setGender(originalData.gender || '');
      setAddress(originalData.address || '');
      setQualification(originalData.qualification || '');
      setPhone(originalData.phone || '');
      setbloodGroup(originalData.bloodGroup || '');
      setAadhaarFile(null);
      setPanFile(null);
      errors.firstName = "";
      errors.lastName = "";
      errors.employeeNumber = "";
      errors.emailId = "";
      errors.dateOfJoining = "";
      errors.designation = "";
      errors.uan = "";
      errors.gender = "";
      errors.address = "";
      errors.qualification = "";
      errors.phone = "";
      errors.emergencyContactName = "";
      errors.emergencyContactNumber = "";
      errors.aadhaarNumber = "";
      errors.aadhaarFile = "";
      errors.panNumber = "";
      errors.panFile = "";
      errors.bloodGroup = "";
      setErrorMessage('');
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleClearFile = async (fileType) => {
    if (fileType === 'aadhaarFile') {
      setAadhaarFile(null);
    } else if (fileType === 'panFile') {
      setPanFile(null);
    }
  };

  const handleEmployeeFirstNameChange = (value) => {
    const nameRegex = /^[A-Za-z\s]+$/;
    if (!value) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        firstName: 'Employee name is required',
      }));
    } else if (!nameRegex.test(value)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        firstName: 'Employee name should contain only alphabets and spaces',
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        firstName: '',
      }));
    }
  };

  const handleEmployeeLastNameChange = (value) => {
    const nameRegex = /^[A-Za-z\s]+$/;
    if (!value) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        lastName: 'Employee name is required',
      }));
    } else if (!nameRegex.test(value)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        lastName: 'Employee name should contain only alphabets and spaces',
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        lastName: '',
      }));
    }
  };
  const validateEmail = (value) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!value) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        emailId: 'Email is required',
      }));
    } else if (!regex.test(value)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        emailId: 'Invalid email format',
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        emailId: '',
      }));
    }
  };

  const handlePhoneChange = (value) => {
    errors.phone = '';
    const phoneRegex = /^(?:\+91|91)?[6789]\d{9}$/;
    if (!value) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        phone: 'Phone number is required',
      }));
    } else if (!phoneRegex.test(value)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        phone: 'Phone number should be in valid format (e.g.,9876543210 or +919876543210)',
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        phone: '',
      }));
    }
  };

  const handleGenderChange = (value) => {
    errors.gender = '';
    if (!value) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        gender: 'gender is required',
      }));
    }else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        gender: '',
      }));
    }
  };

  const handleAddressChange = (value) => {
    errors.address = '';
    if (!value) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        address: 'address is required',
      }));
    }else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        address: '',
      }));
    }
  };

  const handleQualificationChange = (value) => {
    errors.qualification = '';
    if (!value) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        qualification: 'qualification is required',
      }));
    }else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        qualification: '',
      }));
    }
  };
  const handleEmergencyNumberChange = (value) => {
    const emergencyNumberRegex = /^(?:\+91|91)?[6789]\d{9}$/;
    if (!value) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        emergencyContactNumber: 'Emergency Contact Number is required',
      }));
    } else if (!emergencyNumberRegex.test(value)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        emergencyContactNumber: 'Emergency contact should be in valid format (e.g.,9876543210 or +919876543210)',
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        emergencyContactNumber: '',
      }));
    }
  };

  const handleEmergencyContactName = (value) => {
    const nameRegex = /^[A-Za-z\s]+$/;
    if (!value) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        emergencyContactName: 'Emergency name is required',
      }));
    } else if (!nameRegex.test(value)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        emergencyContactName: 'Emergency name should contain only alphabets and spaces',
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        emergencyContactName: '',
      }));
    }
  };

  const handleBloodGroupChange = (value) => {
    errors.bloodGroup = '';
    if (!value) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        bloodGroup: 'Blood Group is required',
      }));
    }else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        bloodGroup: '',
      }));
    }
  };

  const handleAadhaarNumberChange = (value) => {
    const aadhaarRegex = /^\d{12}$/;
    if (!value) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        aadhaarNumber: 'Aadhaar Number is required',
      }));
    } else if (!aadhaarRegex.test(value)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        aadhaarNumber: 'Aadhaar number should be a 12-digit number',
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        aadhaarNumber: '',
      }));
    }
  };

  const handlePanNumberChange = (value) => {
    const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
    if (!value) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        panNumber: 'Pan Number is required',
      }));
    } else if (!panRegex.test(value)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        panNumber: 'PAN number should be in valid format',
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        panNumber: '',
      }));
    }
  };

  const handleDownload = async (fileData) => {
    try {
//       const base64Image = await getFileById(fileId);
      const response = await fetch(`data:${fileData.contentType};base64,${fileData.base64Data}`);
      const blob = await response.blob();

      // Create a link element to trigger the download
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileData.filename); // Set the filename
      document.body.appendChild(link);
      link.click(); // Simulate click to download
      link.remove(); // Clean up the link element
      URL.revokeObjectURL(url); // Revoke the object URL
  } catch (error) {
      console.error('Error downloading file:', error);
  }
}

  if (loading) {
    return <div className='loading-container'><CircularProgress /></div>; 
  }

  return (
    <Box className='main-content'>
        <Tabs value={tabValue} onChange={handleTabChange} aria-label="profile and attachments tabs" 
        variant="scrollable" scrollButtons="auto" allowScrollButtonsMobile 
        sx={{ backgroundColor: '#fff', borderTopLeftRadius:"8px", borderTopRightRadius:"8px" }}>
          <Tab label={
            <Box display="flex" alignItems="center" justifyContent="center"
            flexDirection="column" gap="2px" >
              <FaceIcon/>
              {id === localStorage.getItem('employeeId')  ? 'My Info' : `${originalData.firstName}'s Profile`}
            </Box>
          } 
          style={{color: tabValue === 0 ? theme.palette.primary.main : 'black', fontWeight:'bold', fontSize:'1rem'}} />
          <Tab 
          label={
            <Box display="flex" alignItems="center" justifyContent="center"
            flexDirection="column" gap="2px" >
              <AttachmentIcon/>
              Docs
            </Box>
          } 
          style={{color: tabValue === 1 ? theme.palette.primary.main : 'black', fontWeight:'bold', fontSize:'1rem'}}  />
          {assignedAssets && assignedAssets.length > 0 && (
          <Tab 
          label={
            <Box display="flex" alignItems="center" justifyContent="center"
            flexDirection="column" gap="2px" >
              <DevicesIcon  />
              Assets
            </Box>
          }
          style={{color: assignedAssets && assignedAssets.length <= 0 ? '#808080' : (tabValue === 2 ? theme.palette.primary.main : 'black'), 
          fontWeight:'bold', fontSize:'1rem'}}  />
          )}
        </Tabs>
      <TabPanel value={tabValue} index={0}>
      <Grid container spacing={1} padding={2} marginTop={0} marginLeft={0}
              style={{ backgroundColor: '#fff', alignContent: 'space-around', overflow: "auto", width:'100%' }}>
            {!isProfileComplete &&(
              <Grid item xs={12} md={12} style={{display:'flex', justifyContent:'center'}}>
              <Alert severity='warning' sx={{ maxWidth: '530px', fontSize: {xs: '10px', md: '12px'} }} >Fill out your profile completely in both "My Info" and "Docs" section to unlock full access and maximize your experience.</Alert>
              </Grid>
            )}
          <Grid item xs={12} md={3}>
            <Box style={{ display: "flex", flexDirection: "column", alignItems: "center", paddingTop:"10px" }}>
              {/* <img alt="" src={photo} style={{ width: "180px", height: "150px", borderRadius: "50%" }} />
              <br /> */}
              <Box
                sx={{
                  position: "relative",
                  width: "150px",
                  height: "150px",
                  borderRadius: "50%",
                  backgroundColor: "#808080",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "#fff",
                  fontSize: "40px",
                  marginBottom: "20px",
                }}
              >
                { profileImage ? (
                  // <img src={photo} alt="Profile" style={{ width: "180px", height: "150px", borderRadius: "50%" }} />
                  <>
                    <img src={profileImage} style={{ width: "150px", height: "150px", borderRadius: "50%" }} />
                    <Tooltip title="Remove Profile Picture" placement="top" arrow>
                    <IconButton
                      onClick={handleRemovePhoto}
                      sx={{
                        position: 'absolute',
                        bottom: 10,
                        right: 10,
                        backgroundColor: '#fff',
                        borderRadius: '50%',
                        padding: '4px',
                        boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)',
                      }}
                    >
                      <DeleteOutlineIcon/>
                    </IconButton>
                    </Tooltip>
                  </>
                ) : (
                  <span>{getInitials()}</span>
                )}
              </Box>
              <input
                accept="image/jpeg,image/png"
                style={{ display: 'none' }}
                id="profile-image-upload"
                type="file"
                name="profileImage"
                // onChange={pictureUpload(setPhoto)}
                onChange={handleFileChange}
              />
              <label htmlFor="profile-image-upload">
                <Button variant="contained" component="span" startIcon={<CachedIcon/>} sx={{ whiteSpace: 'nowrap' }}>
                  Change Profile Picture
                </Button>
              </label>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={idCardProvided}
                    onChange={handleCheckboxChange}
                  />
                }
                label="Employee Id card issued?"
                sx={{ 
                  marginTop: "10px",
                  paddingLeft: "16px",
                  '& .MuiFormControlLabel-label': {
                    fontSize: '0.9rem',
                  },
                }}
              />
              <Dialog
                open={isDialogForIdOpen}
                onClose={() => handleDialogClose(false)}
              >
                <DialogTitle className= 'confirmDialogTitle'>
                  ID Card Status Update Confirmation
                  <IconButton aria-label="close" onClick={() => handleDialogClose(false)}>
                    <CancelIcon />
                  </IconButton>
                  </DialogTitle>
                <DialogContent className='confirmDialogContent'>
                  <Alert severity='warning' className='confirmDialogMsg'>
                    Are you sure you want to update the ID card status?
                  </Alert>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => handleDialogClose(false)} variant="contained" color="primary">
                    Cancel
                  </Button>
                  <Button onClick={() => handleDialogClose(true)} variant="contained" color="primary">
                    Confirm
                  </Button>
                </DialogActions>
              </Dialog>
              <Dialog
                open={isDialogOpen}
                onClose={handleCancelDelete}
              >
                <DialogTitle className= 'confirmDialogTitle'>
                  Profile Image Remove Confirmation
                  <IconButton aria-label="close" onClick={handleCancelDelete}>
                    <CancelIcon />
                  </IconButton>
                  </DialogTitle>
                  <DialogContent className='confirmDialogContent'>
                    <Alert severity='warning' className='confirmDialogMsg'>
                      Are you sure you want to remove the profile image?
                    </Alert>
                  </DialogContent>
                <DialogActions>
                  <Button onClick={handleCancelDelete} variant="outlined" color="primary">
                    Cancel
                  </Button>
                  <Button onClick={handleConfirmDelete} variant="contained" color="primary">
                    Confirm
                  </Button>
                </DialogActions>
              </Dialog>
            </Box>
            </Grid>
            <Grid item xs={12} md={9} style={{display:"flex" ,flexWrap:"wrap" , justifyContent:"space-around"}}>
              <TextField
                required
                sx={{width:{ md:"48%", xs:"100%"}}}
                id="firstName"
                label="First Name"
                margin="normal"
                name="firstName"
                value={firstName}
                onChange={(e) => {
                  setFirstName(e.target.value.trimStart())
                  errors.firstName=''
                }}
                onBlur={() => handleEmployeeFirstNameChange(firstName)}
                error={!!errors.firstName}
                helperText={errors.firstName}
              />
              <TextField
                required
                sx={{width:{ md:"48%", xs:"100%"}}}
                id="lastName"
                label="Last Name"
                margin="normal"
                name="lastName"
                value={lastName}
                onChange={(e) => {
                  setLastName(e.target.value.trimStart())
                  errors.lastName=''
                }}
                onBlur={() => handleEmployeeLastNameChange(lastName)}
                error={!!errors.lastName}
                helperText={errors.lastName}
              />
              <TextField
                sx={{width:{ md:"48%", xs:"100%"}}}
                id="employeeID"
                label="Employee ID"
                margin="normal"
                name="employeeID"
                value={employeeNumber}
                disabled={role !== USER_ROLES.ADMIN && role !== USER_ROLES.HR}
                onChange={(e) => setEmployeeNumber(e.target.value)}
                error={errors.employeeNumber}
                helperText={errors.employeeNumber}
              />
              <TextField
                sx={{width:{ md:"48%", xs:"100%"}}}
                id="email"
                label="Email"
                margin="normal"
                name="email"
                value={emailId}
                disabled={role !== USER_ROLES.ADMIN && role !== USER_ROLES.HR}
                onChange={(e)=> {
                  setEmailId(e.target.value)
                  errors.emailId=''
                }}
                onBlur={() => validateEmail(emailId)}
                error={!!errors.emailId}
                helperText={errors.emailId}
              />
              <TextField
                sx={{width:{ md:"48%", xs:"100%"}}}
                id="dateOfJoining"
                label="Date of Joining"
                margin="normal"
                name="dateOfJoining"
                type="date"
                value={dateOfJoining}
                disabled={role !== USER_ROLES.ADMIN && role !== USER_ROLES.HR}
                onChange={(e) => setDateOfJoining(e.target.value)}
                InputLabelProps={{ shrink: true }}
                error={!!errors.dateOfJoining}
                helperText={errors.dateOfJoining}
              />
                <TextField
                  sx={{width:{ md:"48%", xs:"100%"}}}
                  id="designation"
                  label="Designation"
                  margin="normal"
                  name="designation"
                  value={designation}
                  disabled={role !== USER_ROLES.ADMIN && role !== USER_ROLES.HR}
                  onChange={(e) => setDesignation(e.target.value)}
                  error={!!errors.designation}
                  helperText={errors.designation}
                />
                <TextField
                  sx={{width:{ md:"48%", xs:"100%"}}}
                  id="UAN"
                  label="UAN"
                  margin="normal"
                  name="UAN"
                  value={uan}
                  disabled={role !== USER_ROLES.ADMIN && role !== USER_ROLES.HR}
                  onChange={(e) => setUan(e.target.value)}
                  error={!!errors.uan}
                  helperText={errors.uan}
                />
                <FormControl required sx={{width:{ md:"48%", xs:"100%"},"& .MuiOutlinedInput-root": { height: "50px" },}} margin="normal" error={!!errors.gender}>
                  <InputLabel id="gender-label">Gender</InputLabel>
                  <Select
                    labelId="gender-label"
                    id="gender"
                    name="Gender"
                    label="Gender"
                    value={gender}
                    onChange={(e)=> {
                      setGender(e.target.value)
                      errors.gender=''
                    }}
                    onBlur={() => handleGenderChange(gender)}
                  >
                    <MenuItem value="Male">Male</MenuItem>
                    <MenuItem value="Female">Female</MenuItem>
                  </Select>
                  {errors.gender && (
                    <FormHelperText>{errors.gender}</FormHelperText>
                  )}
                </FormControl>
                <TextField
                  required
                  sx={{width:{ md:"48%", xs:"100%"}}}
                  id="address"
                  label="Address"
                  margin="normal"
                  name="Address"
                  value={address}
                  onChange={(e)=> {
                    setAddress(e.target.value.trimStart())
                    errors.address=''
                  }}
                  onBlur={() => handleAddressChange(address)}
                  error={!!errors.address}
                  helperText={errors.address}
                />
                <TextField
                  required
                  sx={{width:{ md:"48%", xs:"100%"}}}
                  id="qualification"
                  label="Qualification"
                  margin="normal"
                  name="Qualification"
                  value={qualification}
                  onChange={(e)=> {
                    setQualification(e.target.value.trimStart())
                    errors.qualification=''
                  }}
                  onBlur={() => handleQualificationChange(qualification)}
                  error={!!errors.qualification}
                  helperText={errors.qualification}
                />
                <TextField
                  required
                  sx={{width:{ md:"48%", xs:"100%"}}}
                  id="phone"
                  label="Phone"
                  margin="normal"
                  name="Phone"
                  value={phone}
                  onChange={(e)=> {
                    setPhone(e.target.value)
                    errors.phone=''
                  }}
                  onBlur={() => handlePhoneChange(phone)}
                  error={!!errors.phone}
                  helperText={errors.phone}
                />
              <TextField
                required
                sx={{width:{ md:"48%", xs:"100%"}}}
                id="emergencyName"
                label="Emergency Contact Name"
                margin="normal"
                name="emergencyName"
                value={emergencyContactName}
                onChange={(e) => {
                  setEmergencyContactName(e.target.value.trimStart())
                  errors.emergencyContactName=''
                }}
                onBlur={() => handleEmergencyContactName(emergencyContactName)}
                error={!!errors.emergencyContactName}
                helperText={errors.emergencyContactName}
              />
           
              <TextField
                required
                sx={{width:{ md:"48%", xs:"100%"}}}
                id="emergencyNumber"
                label="Emergency Contact Number"
                margin="normal"
                name="emergencyNumber"
                value={emergencyContactNumber}
                onChange={(e) => {
                  setEmergencyContactNumber(e.target.value)
                  errors.emergencyContactNumber=''
                }}
                onBlur={() => handleEmergencyNumberChange(emergencyContactNumber)}
                error={!!errors.emergencyContactNumber}  
                helperText={errors.emergencyContactNumber}
              />

              <FormControl required sx={{width:{ md:"48%", xs:"100%"},"& .MuiOutlinedInput-root": { height: "50px" }}} margin="normal" error={!!errors.bloodGroup}>
                <InputLabel id="bloodGroupLabel">Blood Group</InputLabel>
                <Select
                  labelId="bloodGroupLabel"
                  id="bloodGroup"
                  label="bloodGroup"
                  value={bloodGroup}
                  onChange={(e)=> {
                    setbloodGroup(e.target.value)
                    errors.bloodGroup=''
                  }}
                  onBlur={() => handleBloodGroupChange(bloodGroup)}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 100, 
                      }
                    }
                  }}
                >
                  <MenuItem value="A+">A+</MenuItem>
                  <MenuItem value="A-">A-</MenuItem>
                  <MenuItem value="B+">B+</MenuItem>
                  <MenuItem value="B-">B-</MenuItem>
                  <MenuItem value="AB+">AB+</MenuItem>
                  <MenuItem value="AB-">AB-</MenuItem>
                  <MenuItem value="o+">O+</MenuItem>
                  <MenuItem value="o-">O-</MenuItem>
                </Select>
                {errors.bloodGroup && (
                  <FormHelperText>{errors.bloodGroup}</FormHelperText>
                )}
              </FormControl>

              <TextField
                required
                sx={{width:{ md:"48%", xs:"100%"}}}
                id="aadhaarNumber"
                label="Aadhar Number"
                placeholder='eg:398728738444'
                margin="normal"
                name="aadhaarNumber"
                value={aadhaarNumber}
                onChange={(e) => {
                  setAadhaarNumber(e.target.value.trimStart())
                  errors.aadhaarNumber=''
                }}
                onBlur={() => handleAadhaarNumberChange(aadhaarNumber)}
                error={!!errors.aadhaarNumber}  
                helperText={errors.aadhaarNumber}
              />
              <TextField
                required
                sx={{width:{ md:"48%", xs:"100%"}}}
                id="panNumber"
                label="PAN"
                margin="normal"
                name="panNumber"
                placeholder='eg:AFZPK7190K'
                value={panNumber}
                onChange={(e) => {
                  setPanNumber(e.target.value.trimStart())
                  errors.panNumber=''
                }}
                onBlur={() => handlePanNumberChange(panNumber)}
                error={!!errors.panNumber}  
                helperText={errors.panNumber}
              />
              </Grid>
          </Grid>
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
        <Grid container spacing={1} padding={2} marginTop={0} marginLeft={0} alignItems={"center"} gap={1}
              style={{ borderRadius: '0px', backgroundColor: '#fff', alignContent: 'space-around', overflow: "auto", width:'100%' }}>
                {!isProfileComplete &&(
                  <Grid item xs={12} md={12} style={{display:'flex', justifyContent:'center'}}>
                  <Alert severity='warning' sx={{ maxWidth: '530px', fontSize: {xs: '10px', md: '12px'} }} >Fill out your profile completely in both "My Info" and "Docs" section to unlock full access and maximize your experience.</Alert>
                  </Grid>
                )}
            <Grid item xs={12} md={8} style={{display:"flex",alignItems:"center",gap:"20px" ,flexWrap:"wrap"}}>
              <input
                  accept=".jpg,.jpeg,.png,.pdf"
                  style={{ display: 'none' }}
                  id="file-upload-button"
                  type="file"
                  onChange={handleAadhaarFileChange(setAadhaarFile)}
                  
                />
                <label htmlFor="file-upload-button" style={{position:'relative',display:"flex",flexDirection:"column",gap:"5px"}}>
                  <label>Aadhar copy</label>
                  <Button className='upload-btn' variant="outlined" component="span" endIcon={<AttachFileIcon/>} title={aadhaarFile ? aadhaarFile.name : (originalData?.aadhaarFile?.filename)}>
                  <div className='upload-button-ellipsis'>
                  {aadhaarFile ? aadhaarFile.name : (originalData?.aadhaarFile?.filename || "Upload")}
                  </div>
                  </Button>
                  {errors.aadhaarFile && 
                  <div>
                  <Typography variant='error' style={{margin: 0, position:'absolute', width:'200px' }}>{errors.aadhaarFile}</Typography>
                </div>
                  }
                </label>
                {originalData?.aadhaarFile?.filename && !aadhaarFile && (
                    <div style={{ paddingTop: "25px" }}>
                      <a
                        style={{ alignItems: 'center', cursor: "pointer", color: "#0000EE" }}
                        onClick={(e) => { e.preventDefault(); handleDownload(originalData.aadhaarFile); }}
                        title="Download"
                        download
                      >
                        <DownloadIcon style={{paddingTop:"6px"}}/>
                      </a>
                    </div>
                    )}
                    {aadhaarFile && (
                      <Tooltip title="Remove file" placement="right" arrow>
                        <HighlightOffIcon
                          onClick={() => handleClearFile('aadhaarFile')}
                          style={{ cursor: 'pointer',paddingTop:'25px', color: '#cc3333' }}
                        />
                      </Tooltip>
                    )}
            </Grid>
            <Grid item xs={12} md={8} style={{display:"flex", alignItems:"center",gap:"20px",flexWrap:"wrap"}}>
              <input
                  accept=".jpg,.jpeg,.png,.pdf"
                  style={{ display: 'none' }}
                  id="pan-upload-button"
                  type="file"
                  onChange={handlePanFileChange(setPanFile)}
                />
                <label htmlFor="pan-upload-button" style={{position:'relative',display:"flex",flexDirection:"column",gap:"5px"}}>
                <label>PAN copy</label>
                  <Button className='upload-btn' variant="outlined" component="span"  endIcon={<AttachFileIcon/>} title={panFile ? panFile.name : (originalData?.panFile?.filename )}>
                  <div className='upload-button-ellipsis'>
                  {panFile ? panFile.name : (originalData?.panFile?.filename || "Upload")}
                  </div>
                  </Button>
                  {errors.panFile &&  
                  <div>
                    <Typography variant='error' style={{margin: 0, position:'absolute', width:'200px' }}>{errors.panFile}</Typography>
                  </div>
                  }
                </label>
                {originalData?.panFile?.filename && !panFile && (
                    <div style={{ paddingTop: "25px" }}>
                      <a
                        style={{ alignItems: 'center', cursor: "pointer", color: "#0000EE" }}
                        onClick={(e) => { e.preventDefault(); handleDownload(originalData.panFile); }}
                        title="Download"
                        download
                      >
                        <DownloadIcon style={{paddingTop:"6px"}}/>
                      </a>
                    </div>
                    )}

                    {panFile && (
                      <Tooltip title="Remove file" placement="right" arrow>
                        <HighlightOffIcon
                          onClick={() => handleClearFile('panFile')}
                          style={{ cursor: 'pointer',paddingTop:'25px', color: '#cc3333' }}
                        />
                      </Tooltip>
                    )}        
            </Grid>
            {/* {shouldHide &&(
            <>
            <Grid item xs={12} md={3} style={{display:"flex", alignItems:"center" }}>
              <TextField
                required
                style={{width:"100%"}}
                id="otherNumber"
                label="Others"
                margin="normal"
                name="otherNumber"
                value={otherNumber}
                onChange={(e) => setOtherNumber(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={8} style={{display:"flex", alignItems:"center",gap:"20px"}}>
              <input
                  accept=".jpg,.jpeg,.png,.pdf"
                  style={{ display: 'none' }}
                  id="file-upload-button"
                  type="file"
                />
                <label htmlFor="file-upload-button" style={{textAlign:"left"}}>
                  <Button variant="outlined" component="span" style={{marginTop:"7px", minHeight:'50px'}} endIcon={<AttachFileIcon/>} >
                    Upload
                  </Button>
                </label>
                <p style={{overflow:"hidden"}}>{otherFile ? otherFile.name : 'No file chosen'}</p>
            </Grid>  
            </>
            )} */}
        </Grid>
        </TabPanel>
        
        <TabPanel value={tabValue} index={2}>
          <Grid container spacing={1} padding={0} marginTop={1} marginLeft={0} alignItems={"center"}>
            <AssignedAssetsList assetsList={assignedAssets} />
          </Grid>
        </TabPanel>
        {(tabValue === 0 || tabValue === 1) && (
        <Grid container spacing={1} padding={1} marginLeft={0} alignItems={"center"}
          style={{borderBottomLeftRadius: '8px', borderBottomRightRadius: '8px', backgroundColor: '#fff', alignContent: 'space-around', overflow: "auto", width:'100%' }}>
            {errorMessage && (
              <Grid item xs={12} md={12} style={{display:"flex", alignItems:"center", justifyContent:'center'}} >
                <Box sx={{ padding: 1, backgroundColor: '#f8d7da', color: '#721c24', border: '1px solid #f5c6cb', borderRadius: '4px', marginBottom: 1 }}>
                  {errorMessage}
                </Box>
              </Grid>
            )}

          <Box sx={{width:'100%', padding:'0', margin:'0'}}>
          {linearLoading && <LinearProgress />}
            <Divider />
          </Box>
          <Grid item xs={12} md={12}  marginBottom={5} style={{display:"flex", alignItems:"center", gap:"20px"}} sx={{ justifyContent:{xs : 'center', sm: 'center', md:'flex-end'}, marginRight: {xs: 0, sm:0, md: 2,} }}>
            <Button variant="contained" color="primary" sx={{ width: { xs:'35%', sm:"22%", md:'10%'} }} disabled={!hasChanges} onClick={handleDiscard} startIcon={<ClearIcon/>}>
              Cancel
            </Button>
            <Button variant="contained" color="primary" sx={{ width: { xs:'35%', sm:"22%", md:'10%'} }} disabled={!hasChanges || Object.keys(errors).some(key => errors[key])} onClick={handleUpdate} startIcon={<UpdateIcon/>}>
              Update
            </Button>
          </Grid>
        </Grid>
        )}

        {src && (
        <ImageCrop
          src={src}
          openCropModal={openCropModal}
          setOpenCropModal={setOpenCropModal}
          setCroppedImageUrl={setCroppedImageUrlCallback}
        />
      )}


    </Box>
  );
}