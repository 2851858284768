import React, { useState, useEffect } from 'react';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import { getLeavesByApproverId, updateLeave, getAllLeaves, getLeavesByEmployeeId, fetchLeavesByEmployeeIdAndDate } from '../../services/leaveServices';
import { Alert, Box, Button, Dialog, DialogTitle, DialogContent, DialogActions, FormControl, Grid, IconButton, InputLabel, LinearProgress, MenuItem, TextField, Select, useTheme, Tooltip } from '@mui/material';
import {Cancel as CancelIcon} from '@mui/icons-material';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import { format, parseISO } from 'date-fns';
import { NotificationManager } from 'react-notifications';
import moment from 'moment';
import { SvgIcons } from '../../assets/icons/SvgIcons';
import { getEmployeeById, getAllEmployees, getAllEmployeeNames  } from '../../services/employeeService';
import { USER_ROLES , LEAVE_STATUS, ROWS_PER_PAGE, PAGE_SIZE_OPTIONS } from '../../Constants';
import '../../CSS/dialogPopup.css';
import { getRoleNameById } from '../../services/UserRolesServices';


function EditToolbar({employees, selectedEmployee, handleEmployeeChange, selectedYear, handleYearChange, selectedMonth, handleMonthChange }) {
    const years = Array.from({ length: 2 }, (_, i) => new Date().getFullYear() - i);
    const months = moment.months(); 
  
    return (
      <GridToolbarContainer sx={{ height: { xs: '110px', md: '60px' }, borderBottom:'1px solid lightgray', paddingBottom:'10px'}} >
        <Grid container rowGap={2}>
            <Grid item xs={12} sm={2.5} md={1.6} >
                <FormControl sx={{ width: {xs: '100%', sm: '90%'} }} size="small">
                <InputLabel id="employee-select-label">Filter by Name</InputLabel>
                <Select
                    labelId="employee-select-label"
                    value={selectedEmployee}
                    label="Filter by Name"
                    onChange={handleEmployeeChange}
                >
                    <MenuItem value={0}>All</MenuItem>
                {employees.map((employee) => (
                    <MenuItem key={employee._id} value={employee._id}>
                        {employee.firstName} {employee.lastName}
                    </MenuItem>
                    ))}
                </Select>
                </FormControl>
            </Grid>

            <Grid item xs={6} sm={2.5} md={1.6}>
                <FormControl sx={{ width: {xs: '90%', sm: '90%'} }} size="small" >
                    <InputLabel id="year-select-label">Filter by Year</InputLabel>
                    <Select
                        labelId="year-select-label"
                        value={selectedYear}
                        label="Filter by Year"
                        onChange={handleYearChange}
                    >
                        <MenuItem value={0}>All</MenuItem>
                        {years.map((year) => (
                            <MenuItem key={year} value={year}>
                                {`${year} - ${(year+1).toString().slice(-2)}`}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={6} sm={2.5} md={1.6}>
                <FormControl sx={{ width: {xs: '100%', sm: '90%'} }} size="small" >
                <InputLabel id="month-select-label">Filter by Month</InputLabel>
                    <Select
                    labelId="month-select-label"
                    value={selectedMonth}
                    label="Filter by Month"
                    onChange={handleMonthChange}
                    >
                     <MenuItem value={0}>All</MenuItem>
                          {months.map((month, index) => (
                     <MenuItem key={index} value={index + 1}>
                          {month}
                    </MenuItem>
                                ))}
                    </Select>
                </FormControl>
            </Grid>

        </Grid>
      </GridToolbarContainer>
    );
  }


export default function LeaveApproval() {
    const theme = useTheme();
    const employeeId = localStorage.getItem("employeeId");
    const [leaves, setLeaves] = useState([]);
    const [loading, setLoading] = useState(true);
    const [openRejectDialog, setOpenRejectDialog] = useState(false);
    const [selectedLeave, setSelectedLeave] = useState(null);
    const [adminRejectComment, setAdminRejectComment] = useState('');
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [dialogTitle, setDialogTitle] = useState('');
    const [warningMessage, setWarningMessage] = useState('');
    const [approverRole, setApproverRole] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [selectedEmployee, setSelectedEmployee] = useState();
    // const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [selectedYear, setSelectedYear] = useState(() => {
        const today = new Date();
        const currentYear = today.getFullYear();
        return today.getMonth() < 3 ? currentYear - 1 : currentYear;
    });
    const [selectedMonth, setSelectedMonth] = useState('');

    useEffect(() => {
        fetchAllEmployees();
        fetchApproverRole();
    }, []);

    useEffect(() => {
        if(selectedEmployee !== undefined){
            fetchLeaveDetails();
        }
        else{
            setLoading(false);
        }
    }, [selectedEmployee]);

    const fetchApproverRole = async () =>{
        const roleId = localStorage.getItem("roleId");
        const response = await getRoleNameById(roleId);
        setApproverRole(response.roleName);
    }

    const fetchLeaveDetails = async () => {
        try {
            setLoading(true);

            if (approverRole === USER_ROLES.ADMIN) {
                fetchLeavesByEmployee();
            } else {
                fetchLeavesByApproverId(employeeId);
            }
            setLoading(false);
        } catch (error) {
            console.error('Error fetching employee details:', error);
            setLoading(false);
        }
    };

    const fetchAllEmployees = async () => {
        try {
            const employeeList = await getAllEmployeeNames();
            setEmployees(employeeList);
        } catch (error) {
            console.error('Error fetching employee list:', error);
        }
    };

    const fetchLeavesByApproverId = async (approverId) => {
        try {
            const fetchedLeaves = await getLeavesByApproverId(approverId, selectedEmployee);
            setLeaves(fetchedLeaves);
        } catch (error) {
            console.error('Error fetching leaves:', error);
        }
    };

    const fetchLeavesByEmployee = async () => {
        try {
            if (selectedEmployee === 0) {
                const allLeaves = await getAllLeaves();
                setLeaves(allLeaves);
            } else {
                const employeeLeaves = await getLeavesByEmployeeId(selectedEmployee);
                setLeaves(employeeLeaves);
            }
        } catch (error) {
            console.error('Error fetching the leaves:', error);
        }
    };

    const handleEmployeeChange = (e) => {
        setSelectedEmployee(e.target.value);
    }

    const handleYearChange = (e) => {
        setSelectedYear(e.target.value);
    }

    const handleMonthChange = (e) => {
        setSelectedMonth(e.target.value);
    }

    const filteredLeaves = leaves.filter(leave => {
        const leaveDate = parseISO(leave.fromDate);
        const leaveYear = leaveDate.getFullYear();
        const leaveMonth = leaveDate.getMonth() + 1;

        const financialYearStart = new Date(`${selectedYear}-04-01`);
        const financialYearEnd = new Date(`${parseInt(selectedYear) + 1}-03-31`);

        const isYearMatch = !selectedYear ||
            (leaveDate >= financialYearStart && leaveDate <= financialYearEnd);

        const isMonthMatch = !selectedMonth || leaveMonth === parseInt(selectedMonth);

        return isYearMatch && isMonthMatch;
    });    
      
    const formatDate = (isoDateString) => {
        if (!isoDateString) return '';

        const date = parseISO(isoDateString);
        return format(date, 'dd-MM-yyyy');
    };

    const handleApproveClick = async (row) => {
        try {
            const currentDate = new Date();
            const employeeLeaves = await fetchLeavesByEmployeeIdAndDate(row.employeeId._id, currentDate);

            const pendingNonLopLeave = employeeLeaves.find(
                (leave) => leave.leaveType !== 'LOP' && leave.status === LEAVE_STATUS.PENDING
            );
    
            if (row.leaveType === 'LOP' && pendingNonLopLeave) {
                setConfirmationOpen(true);
                setDialogTitle('Approve Leave Request');
                setWarningMessage('Cannot approve the LOP leave request. There are pending non-LOP leave requests for this employee.')
            } 
            else {
                await updateLeave(row._id, LEAVE_STATUS.APPROVED);
                NotificationManager.success("Leave request approved successfully");
                fetchLeaveDetails();
            }
        } catch (error) {
            NotificationManager.error(error.response?.data?.message || 'An error occurred while approving the leave request');
        }
    };
    

    const handleRejectClick = async (row) => {
        setSelectedLeave(row);
        const currentDate = new Date();
        const employeeLeaves = await fetchLeavesByEmployeeIdAndDate(row.employeeId._id, currentDate)
        const pendingLopLeave = employeeLeaves.find(
            (leave) => leave.leaveType === 'LOP' && leave.status === LEAVE_STATUS.PENDING
        );
        if (row.leaveType !== 'LOP' && row.status === LEAVE_STATUS.PENDING && pendingLopLeave) {
            setConfirmationOpen(true);
            setDialogTitle('Reject Leave Request');
            setWarningMessage('Cannot reject the leave request. There is a pending LOP leave request for this employee.')
        } else {
            setOpenRejectDialog(true);
        }
    };
    
    const handleRejectConfirm = async () => {
        try {
            await updateLeave(selectedLeave._id, LEAVE_STATUS.REJECTED, adminRejectComment);
            NotificationManager.success("Leave request rejected successfully");
            setAdminRejectComment('');
            setOpenRejectDialog(false);
            fetchLeaveDetails();
        } catch (error) {
            NotificationManager.error(error.response?.data?.message || 'An error occurred while rejecting the leave request');
        }
    };

    const handleDialogClose = () => {
        setOpenRejectDialog(false);
        setAdminRejectComment('');
    };

    const handleRejectConfirmation = () => {
        setConfirmationOpen(false);
        setDialogTitle('');
        setWarningMessage('');
    };
    const handleRejectComment=(e)=>{
        const value = e.target.value.replace(/^\s+/, ''); // Remove leading spaces
         setAdminRejectComment(value);
    }

    const columns = [
        { field: 'firstName', headerName: <strong>Employee Name</strong>, flex: 1, minWidth: 200,
            valueGetter: (row, value) => `${value.employeeId?.firstName || ''} ${value.employeeId?.lastName || ''}`,
        },
        { field: 'fromDate', headerName: <strong>From Date</strong>, flex: 1, minWidth: 150,
            valueGetter: (params) => formatDate(params),
        },
        { field: 'toDate', headerName: <strong>To Date</strong>, flex: 1, minWidth: 150,
            valueGetter: (params) => formatDate(params),
        },
        { field: 'reason', headerName: <strong>Reason</strong>, flex: 1.5, minWidth: 200, },
        { field: 'numberOfDays', headerName: <strong>Number of Days</strong>, flex: 1, minWidth: 100, },
        { field: 'leaveType', headerName: <strong>Leave Type</strong>, flex: 1, minWidth: 150, },
        { 
            field: 'status', 
            headerName: <strong>Status</strong>, 
            flex: 1, minWidth: 150,
            renderCell: (params) => {
                let fillColor;
    
                switch (params.value) {
                    case LEAVE_STATUS.PENDING:
                        fillColor = LEAVE_STATUS.PENDING_COLOR;
                        break;
                    case LEAVE_STATUS.APPROVED:
                        fillColor = LEAVE_STATUS.APPROVED_COLOR;
                        break;
                    case LEAVE_STATUS.REJECTED:
                        fillColor = LEAVE_STATUS.REJECTED_COLOR;
                        break;
                    case LEAVE_STATUS.CANCELED:
                        fillColor = LEAVE_STATUS.CANCELED_COLOR;
                        break;
                    default:
                        return null;
                }

                return (
                        <SvgIcons marginTop='8px' fillColor={fillColor} text={params.value} />
                );
            }
        },
        {
            field: 'actions',
            headerName: <strong>Actions</strong>,
            flex: 1, minWidth: 250,
            sortable: false,
            renderCell: (params) => (
                <Box
                    sx={{
                        pt:1.2,
                        display: 'flex',
                        alignItems:'center',
                        gap: '10px',
                        width: '100%', 
                    }}
                >
                    <Button 
                        variant="contained" 
                        color="success"
                        style={{ maxHeight: '30px', width:'95px' }} 
                        disabled={params.row.status !== LEAVE_STATUS.PENDING}
                        startIcon={<ThumbUpAltIcon/>} 
                        onClick={() => handleApproveClick(params.row)}
                    >
                        Approve
                    </Button>
                    <Tooltip 
                      title={params.row.employeeRejectRequestComment} arrow>
                        <Button 
                        variant="contained"
                        style={{ maxHeight: '30px', width:'85px' }} 
                        disabled={!(params.row.status === LEAVE_STATUS.PENDING || (params.row.status === LEAVE_STATUS.APPROVED && params.row.isRejectRequested === true))} 
                        startIcon={<ThumbDownAltIcon/>}
                        onClick={() => handleRejectClick(params.row)}
                    >
                        Reject
                    </Button>
                      </Tooltip>
                </Box>
            )
        }        
    ];

    return (
        <Box className = 'main-content'>
                <Grid className='grid-container-header grid-container-header-with-nofilters align-header-center'>
                    Approve Leaves
                </Grid>

                <Grid container padding={2} style={{ backgroundColor: '#fff' }}>
                    <Grid item xs={12} style={{ height: "500px" }}>
                        <DataGrid
                            localeText={{
                                noRowsLabel: selectedEmployee === undefined 
                                    ? "Select an employee to see their leave details." 
                                    : "There are no leave requests submitted by the employee."
                            }}
                            rows={filteredLeaves}
                            getRowId={(row) => row._id}
                            columns={columns}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: ROWS_PER_PAGE,
                                    },
                                },
                            }}
                            pageSizeOptions={PAGE_SIZE_OPTIONS}
                            pagination
                            components={{
                                LoadingOverlay: LinearProgress,
                            }}
                            slots={{
                                toolbar: EditToolbar,
                              }}
                              slotProps={{
                                toolbar: {employees, selectedEmployee, handleEmployeeChange, selectedYear, handleYearChange, selectedMonth, handleMonthChange },
                              }}
                            loading={loading}
                            sx={{
                                '& .MuiDataGrid-selectedRowCount': {
                                  display: 'none',
                                },
                              }}
                        />
                    </Grid>
                </Grid>

            <Dialog
                open={openRejectDialog}
                onClose={handleDialogClose}
                aria-labelledby="reject-dialog-title"
                PaperProps={{ sx: { width: '400px' } }}
            >
                <DialogTitle id="reject-dialog-title" className="confirmDialogTitle">Reject Leave Request Confirmation
                <IconButton aria-label="close" onClick={handleDialogClose}>
                    <CancelIcon />
                </IconButton>
                </DialogTitle>
                <DialogContent>
                    <TextField
                        required
                        autoFocus
                        margin="dense"
                        id="rejectComment"
                        label="Comment"
                        type="text"
                        fullWidth
                        multiline
                        rows={3}
                        value={adminRejectComment}
                        onChange={handleRejectComment}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} variant ='contained' sx={{ width: '80px' }}>Cancel</Button>
                    <Button onClick={handleRejectConfirm} variant ='contained' sx={{ width: '80px' }} disabled={!adminRejectComment}>Confirm</Button>
                </DialogActions>
            </Dialog>

            <Dialog
                    open={confirmationOpen}
                    onClose={handleRejectConfirmation}
                    PaperProps={{ sx: { width: '400px' }}}
                >
                    <DialogTitle className="confirmDialogTitle">
                        {dialogTitle}
                        <IconButton aria-label="close" onClick={handleRejectConfirmation} >
                            <CancelIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent className='confirmDialogContent'>
                        <Alert severity='warning' className='confirmDialogMsg'>
                            {warningMessage}
                        </Alert>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleRejectConfirmation} variant='contained'>
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>

        </Box>
    );
}
