import axios from 'axios';
import axiosInstance from './axiosInstance';
import { BASE_URL } from '../Constants';
const employeeId = localStorage.getItem("employeeId");

export const getAssetsList = async () => {
    try {
        const response = await axiosInstance.get(`${BASE_URL}/assets/`);
        return response.data;
    } catch (error) {
        console.error('Error fetching Assets:', error);
        throw error;
    }
};

export const getAssetTypes = async () => {
    try {
        const response = await axiosInstance.get(`${BASE_URL}/assets/types`);
        return response.data;
    } catch (error) {
        console.error('Error fetching Asset types:', error);
        throw error;
    }
};

export const getAssetModels = async () => {
    try {
        const response = await axiosInstance.get(`${BASE_URL}/assets/models`);
        return response.data;
    } catch (error) {
        console.error('Error fetching Asset types:', error);
        throw error;
    }
};

export const createAsset = async (newAsset) => {
    try {
        const response = await axiosInstance.post(`${BASE_URL}/assets/`, newAsset);
        return response.data;
    } catch (error) {
        console.error('Error creating Assets:', error);
        throw error;
    }
};

export const updateAsset = async (id, assetData) => {
    try {
        assetData = {
          ...assetData,
          lastModifiedBy: employeeId,
        }
    const response = await axiosInstance.put(`${BASE_URL}/assets/${id}`, assetData);
    return response.data;
    } catch (error) {
        console.error('Error updating  employee:', error);
        throw error;
    }
  };

  export const createAssetType = async (newAssetType) => {
    try {
        const response = await axiosInstance.post(`${BASE_URL}/assets/type`, newAssetType);
        return response.data;
    } catch (error) {
        console.error('Error creating Assets Type:', error);
        throw error;
    }
};

export const createAssetModel = async (newAssetModel) => {
    try {
        const response = await axiosInstance.post(`${BASE_URL}/assets/model`, newAssetModel);
        return response.data;
    } catch (error) {
        console.error('Error creating Assets Model:', error);
        throw error;
    }
};