import axiosInstance from './axiosInstance';
import { BASE_URL} from '../Constants';

export const saveUserRolesPermission = async (data) => {
    try {
        
        // const checkResponse = await axiosInstance.get(`${BASE_URL}/moduleUserRole/${data.userRoleId}`);
        // if (checkResponse.status === 200) {
            const updateResponse = await axiosInstance.put(`${BASE_URL}/moduleUserRole/${data.userRoleId}`, data);
            return updateResponse;
        // }
    } catch (error) {
        if (error.response && error.response.status === 404) {
            const createResponse = await axiosInstance.post(`${BASE_URL}/moduleUserRole`, data);
            return createResponse;
        } else {
            console.error('Error creating or updating user roles:', error);
            throw error;
        }
    }
};


