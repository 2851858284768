import React from 'react';
import { Box, Card, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography,IconButton} from '@mui/material';


const AssignedAssetItems = ({ assetTypeLabel, icon, filteredAssets }) => {

  const [open, setOpen] = React.useState(false);
 
  const handleTooltipOpen = () => {
    setOpen(true);
   };

  const handleTooltipClose = () => {
    setOpen(false);
   };
   
  return (
    <Card>
      <Box
        padding={1}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box display="flex" alignItems="center" justifyContent="center" >
          <Box ml={2} display="flex" flexDirection="column" alignItems="center">
          <Tooltip title={assetTypeLabel}
            open={open}
            onClose={handleTooltipClose}
            onOpen={handleTooltipOpen} 
            leaveTouchDelay={10000}
            arrow
          >
            <IconButton onClick={() => setOpen(!open)}
                onMouseEnter={handleTooltipOpen}
                onMouseLeave={handleTooltipClose}  >
               {icon}
           </IconButton>
          </Tooltip>
         <Typography
           gutterBottom
          sx={{ textAlign: 'center', whiteSpace: 'nowrap' }}
           >
         </Typography>
          </Box>
        </Box>

        <Box ml={4} flex="1" overflow="hidden">
          <Table size="small">
            <TableHead>
              <TableRow style={{ backgroundColor: '#C0C0C0' }}>
                <TableCell align="center"><strong>Asset ID</strong></TableCell>
                <TableCell align="center"><strong>Model</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredAssets.map((asset, idx) => (
                <TableRow key={idx} style={{backgroundColor: 'transparent'}}>
                  <TableCell align="center" style={{ padding: '4px' }}>{asset.assetId}</TableCell>
                  <TableCell align="center" style={{ padding: '4px' }}>{asset.model?.model}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Box>
    </Card>
  );
};

export default AssignedAssetItems;
