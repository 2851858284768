import React, { useEffect, useState } from 'react';
import {getNewJoinees, getLongServiceEmpList } from '../services/employeeService';
import { Box, Grid, useTheme, CircularProgress } from '@mui/material';
import EmployeeCarousel from '../../src/Dashboard/EmployeeCarousel';
import './dashboard.css';

export default function Dashboard() {
  const theme = useTheme();
  const [newJoinees, setNewJoinees] = useState([]);
  const [longServiceEmp, setLongServiceEmp] = useState([]);
  const [loading, setLoading]= useState(true)

useEffect(() => {
  fetchNewjoinee();
  fetchLongServiceEmpList();
}, []);

const fetchNewjoinee= async ()=>{
  setLoading(true)
  try{
  const response = await getNewJoinees();
   setNewJoinees(response);
   setLoading(false)
  }
  catch (error) {
    console.error("Failed to fetch employees:", error);
  }
}

const fetchLongServiceEmpList= async ()=>{
  setLoading(true)
  try{
  const response = await getLongServiceEmpList();
   setLongServiceEmp(response);
   setLoading(false)
  }
  catch (error) {
    console.error("Failed to fetch employees:", error);
  }
}

if (loading) {
  return <div className='loading-container'><CircularProgress /></div>;
}

  return (
  <Box className = 'main-content' >
       <Grid className='grid-container-header grid-container-header-with-nofilters align-header-center'>
       My Dashboard
       </Grid>

      <Box className="employee-carousel-container">
        <Grid container spacing={2} className="employee-carousel-grid">
          {newJoinees.length > 0 && (
            <Grid item xs={12} md={longServiceEmp.length > 0 ? 6 : 12} lg={longServiceEmp.length > 0 ? 6 : 12} className="employee-carousel-grid-item">
              <Box className="employee-carousel-box">
                <Box className="employee-carousel-content">
                  <EmployeeCarousel title="New Joinees" employees={newJoinees} />
                </Box>
              </Box>
            </Grid>
          )}

          {longServiceEmp.length > 0 && (
            <Grid item xs={12} md={newJoinees.length > 0 ? 6 : 12} lg={newJoinees.length > 0 ? 6 : 12} className="employee-carousel-grid-item">
              <Box className="employee-carousel-box">
                <Box className="employee-carousel-content">
                  <EmployeeCarousel title="Employees Completing 5 Years" employees={longServiceEmp} />
                </Box>
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>
  </Box>
);
}