import React, { useState, useEffect } from 'react';
import { Box, Button, Typography , CircularProgress } from '@mui/material';
import LoginIcon from '@mui/icons-material/Login';
import { useNavigate } from 'react-router-dom';
import { useGoogleLogin } from '@react-oauth/google';
import { getUserInfo } from '../../services/loginService';
import { useAuth } from '../../context/AuthContext';
import { getEmployeeByEmailId , saveLastLoggedIn , createEmployee} from '../../services/employeeService';
import { checkExistsOrCreateRole} from '../../services/UserRolesServices';
import { USER_ROLES, BASE_URL} from '../../Constants';
import PhyelementsLogo from '../../assets/logos/phyelements_logo.png'


function LoginPage() {
  const navigate = useNavigate();
  const { setUserToken } = useAuth();

  const [ user, setUser ] = useState([]);
  const [ profile, setProfile ] = useState([]);
  const adminList = JSON.parse(process.env.REACT_APP_ADMIN_LIST || "[]");  

  const [isLoading, setIsLoading] = useState(false);

  const login = useGoogleLogin({
    onSuccess: async(codeResponse) => {
      setIsLoading(true);
      setUser(codeResponse);
      try {
        const userInfo = await getUserInfo(codeResponse.access_token);
        setProfile(userInfo);
        
        const response = await fetch(`${BASE_URL}/auth/generatejwttoken`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({ accessToken: codeResponse.access_token }),
      });
      if (!response.ok) throw new Error('Failed to generate JWT token');
        if (response.ok) {
          const data = await response.json();
          setUserToken(data.token);
          let isEmployee;
          try {
              isEmployee = await getEmployeeByEmailId(data.token, userInfo.email);
          } catch (error) {
            if (error.message === 'Employee not found') {
                // If 403 error is thrown, redirect to the login-disabled page
                navigate('/login-disabled');
            } else {
                console.error('Failed to get employee information:', error);
                return;
            }
          }
          if (isEmployee.length == 0) {
            const newEmployee = {
              firstName: userInfo.given_name,
              lastName: userInfo.family_name,
              emailId: userInfo.email,
              activeStatus: true,
              // assignedRoleId: roleId,
            }
            const isAdmin = adminList.includes(userInfo.email);
            if(isAdmin){
              const adminRoleId = await checkExistsOrCreateRole( data.token, USER_ROLES.ADMIN , true , "Admin Role Added internally");
              newEmployee.assignedRoleId = adminRoleId;
            } else {
              const employeeRoleId = await checkExistsOrCreateRole(data.token, USER_ROLES.EMPLOYEE , true , "Employee Role Added internally");
              newEmployee.assignedRoleId = employeeRoleId;
            }
            const response = await createEmployee(newEmployee);
            isEmployee = response.savedEmployee;
          }
          localStorage.setItem('roleId', isEmployee.assignedRoleId);
          localStorage.setItem('employeeId', isEmployee._id);
          localStorage.setItem('rmid', isEmployee.reportingTo);
          await saveLastLoggedIn(data.token, isEmployee._id);
          setIsLoading(false);
          navigate(`/updateprofile/${isEmployee._id}`); 
        } else {
          console.error('Failed to generate JWT token');
        }
        setTimeout(() => {
          logout();
        }, [900000])
      } catch (error) {
        console.error('Failed to get user info:', error);
      }
    },
    onError: (error) => console.error('Login Failed:', error)
  });

  const logout = () =>{
    sessionStorage.clear();
    localStorage.clear();
    var newWindow = window.open('https://accounts.google.com/Logout', 'Disconnect from Google', 'menubar=no,status=no,location=no,toolbar=no,scrollbars=no,top=200,left=200');
    setTimeout(function () {
      if (newWindow) newWindow.close();
      navigate('/login')
    }, 4000);
  }
  return (
    <>
    {isLoading ? (
      <div className='loading-container'>< CircularProgress /> </div>
    ) : (
      <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        backgroundColor: "#B4B4B4"
      }}
    >
      <Box style={{position:'relative', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', backgroundColor: "#FFFF", padding: "10px 30px 10px 30px", borderRadius: "15px" }} sx={{ width: { xs: "75%", md: "28%", sm: "53%" } }}> 
        <img alt="phyelements" src={PhyelementsLogo} style={{ }} /> <br/>
        <Typography sx={{fontSize:{md:"1.8rem", sm:"1.6rem", xs:"1.1rem"}, fontWeight:"bold"}}>phye HRMS Portal</Typography>
        <br />
        <Button
          variant="contained"
          startIcon={<LoginIcon />}
          onClick={() => login()}
          sx={{
             borderRadius: "50px",
          }}
        >
          Click here to Login
        </Button>
        <br/>
        <br/>
        <Box sx={{width: "100%"}}  >
            <Typography sx={{fontWeight:"bold", padding:"0px", margin:"0px"}}>Instructions:</Typography>
            <ul style={{ margin:"0px", paddingLeft:"20px" }}>
            <li><Typography  style={{color:'#808080', fontSize:"0.8em"}}> Unable to login? Please contact Administrator.</Typography></li>
            <li><Typography style={{color:'#808080', fontSize:"0.8em"}}> Use your official email to login.</Typography></li>
            </ul>            
        </Box>    
        <Typography sx={{ marginTop:"2em", fontSize:"0.8em", color:'#808080', }}>Disclaimer: For internal purpose only </Typography> 
      </Box>
        
    </Box>
    )}
   </>
  );
}

export default LoginPage;
