import * as React from "react";
import { Box, Container, Typography } from "@mui/material";

export default function Footer() {
  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 0,
        width: '100%',
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[200]
            : theme.palette.grey[800],
        p: 0,
        opacity:1
      }}
      component="footer"
    >
      <Container maxWidth="sm" className="footer-text">
        <Typography variant="body2" color="#C9A26B" align="center" sx={{fontWeight:'bolder'}}>
          {"Copyright © "}
          2025 PHYELEMENTS LLP · All rights reserved.
        </Typography>
      </Container>
    </Box>
  );
}
