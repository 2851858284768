import React from 'react';
import { FormControlLabel, Switch } from '@mui/material';
import { STATUS } from '../../Constants';
import Typography from '@mui/material/Typography';

const StatusSwitch = ({ checked, onChange }) => {
    
  return (
    <FormControlLabel
      control={<Switch checked={checked} onChange={onChange} color="primary" size="small"/>}
      label={
        <Typography className="status-switch">
          {checked ? STATUS.ACTIVE : STATUS.INACTIVE}
        </Typography>
      }
      sx={{ m: 0 }}
    />
  );
};

export default StatusSwitch;
