import React, { useState, useEffect, useCallback } from 'react';
import { Alert, Button, Dialog, DialogTitle, DialogContent, DialogActions, FormControl, FormControlLabel, Grid, IconButton, InputLabel, MenuItem, TextField, Select, Switch, useTheme, Typography, LinearProgress} from '@mui/material';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import CancelIcon from '@mui/icons-material/Cancel';
import { updateModule, insertModule, getModules } from '../../services/ModuleServices';
import AllRoutes from '../../utils/AllRoutes';
import { STATUS } from '../../Constants';

const normalizeInput = (input) => {
    return input.trim().replace(/\s+/g, ' ').toLowerCase();
};

const ModulePopup = ({ open, handleClose, module, refreshData, isUpdate ,modules }) => {
    const theme = useTheme();
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [activeStatus, setActiveStatus] = useState(true);
    const [nameError, setNameError] = useState('');
    const [isCheck, setIsCheck] = useState(false);
    const [menu, setMenu] = useState([]);
    const [selectedMenu, setSelectedMenu] = useState('');
    const [usedMenuItems, setUsedMenuItems] = useState([]);
    const [moduleConfirmPopup, setModuleConfirmPopup] = useState(false);
    const [loading, setLoading] = useState(false);

    const fetchModuleListValue = () => {
        try {
            // const [moduleItem] = await Promise.all([getModules()]);
            const NaNMenu = {
                icon: 'unknown_icon', 
                title: 'None', 
                to: '/nan', 
                items: [] ,
                key:'none'
              };
            const fullMenus = [...AllRoutes, NaNMenu];
            setMenu(fullMenus);
            const usedItems = modules
                .filter(mod => mod._id !== module?._id)
                .map(mod => mod.menuItem);
            setUsedMenuItems(usedItems);
        } catch (error) {
            console.error("Error fetching roles:", error);
        }
    
    };

    useEffect(() => {
        if (open) {
            fetchModuleListValue();
        }
    }, [open ]);

    useEffect(() => {
        if (module) {
            setName(module.name || '');
            setDescription(module.description || '');
            setActiveStatus(module.activeStatus);
            setSelectedMenu(module.menuItem || '');
        } else {
            setName('');
            setDescription('');
            setActiveStatus(true);
            setSelectedMenu('');
        }
    }, [module]);

    useEffect(() => {
        const nameRegex = /^[a-zA-Z0-9\s]*$/;
        if (!nameRegex.test(name)) {
            setNameError('Only letters and numbers');
        } else {
            setNameError('');
        }

        const hasChanges = isUpdate
            ? (name.trim() !== '' && description.trim() !== '' &&
                (name !== module?.name || description !== module?.description || activeStatus !== module?.activeStatus || selectedMenu !== module?.menuItem._id))
            : (name.trim() !== '' && description.trim() !== '' && selectedMenu !== '');

        setIsCheck(isUpdate ? hasChanges : (name.trim() !== '' && description.trim() !== '' && selectedMenu !== ''));
    }, [name, description, activeStatus, selectedMenu, module, isUpdate]);

    const handleCloseModal = useCallback(() => {
        handleClose();
        setName('');
        setDescription('');
        setActiveStatus(true);
        setNameError('');
        setSelectedMenu('');
    }, [handleClose]);

    const handleSubmit = useCallback(async (event) => {
        setIsCheck(false)
        // if(loading) return;
        event.preventDefault();
        const normalizedName = normalizeInput(name);
        try {
            setLoading(true);
            if (isUpdate) {
                const updatedModuleData = { name, description, activeStatus, menuItem: selectedMenu };

                if (name !== module.name) {
                    // const existingModules = await getModules();
                    const isDuplicate = modules.some(existingModule =>
                        existingModule.id !== module.id && normalizeInput(existingModule.name) === normalizedName);

                    if (isDuplicate) {
                        NotificationManager.warning('Module with the same name already exists');
                        return;
                    }
                }

                await updateModule(module._id, updatedModuleData);
                handleCloseModal();
                refreshData();
                NotificationManager.success('Module updated successfully!');
            } else {
                // const existingModules = await getModules();
                const isDuplicate = modules.some(existingModule =>
                    normalizeInput(existingModule.name) === normalizedName
                );

                if (isDuplicate) {
                    NotificationManager.warning('Module with the same name already exists');
                    return;
                }

                const newModuleData = { name, description, activeStatus, menuItem: selectedMenu };
                await insertModule(newModuleData);
                handleCloseModal();
                refreshData();
                NotificationManager.success('Module added successfully!');
            }
        } catch (err) {
            console.error("Error submitting form:", err);
            NotificationManager.error('Failed to process module.');
        }finally{
            setLoading(false); 
          }
    }, [name, description, activeStatus, selectedMenu, isUpdate, module, refreshData, handleCloseModal]);

    const handleConfirmPopupClose = () => {
        setModuleConfirmPopup(false);
    };
    
    const handleConfirmPopupContinue = () => {
        setSelectedMenu('none');
        setModuleConfirmPopup(false);
    };

    return (
        <>
            <Dialog open={open} onClose={handleCloseModal} PaperProps={{ sx: { width: '400px' }, className: 'dialog' }}>
                <DialogTitle className= 'dialogTitle'>
                    {isUpdate ? 'Update Module' : 'Add Module'}
                    <IconButton aria-label="close" onClick={handleCloseModal} className='dialogCloseIcon'>
                        <CancelIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent  className='dc-bottom-padding'>
                <Grid container spacing={1.5}>
                <Grid item xs={12}>
                    <TextField
                        sx={{ mt:1.5 }}
                        label="Module Name"
                        type="text"
                        name="name"
                        fullWidth
                        required
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        error={!!nameError}
                        helperText={nameError}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Description"
                        multiline
                        rows={3}
                        value={description}
                        required
                        onChange={(e) => setDescription(e.target.value)}
                    />
                  </Grid>
                </Grid>
                    <FormControl sx={{ width: '100%', mt:1.5 }} required>
                        <InputLabel id="associated-menu-label">Associated Menu</InputLabel>
                        <Select
                            labelId="associated-menu-label"
                            id="associated-menu-select"
                            value={selectedMenu}
                            label="Associated Menu"
                            onChange={(event) => {
                                if (event.target.value === 'none') {
                                    setModuleConfirmPopup(true);
                                } else {
                                    setSelectedMenu(event.target.value);
                                }
                            }}
                            required
                        >
                            {menu.filter(menuItem => !usedMenuItems.includes(menuItem._id)).map((menuItem) => (
                                <MenuItem
                                    key={menuItem.key}
                                    value={menuItem.key}
                                    disabled = {usedMenuItems.includes(menuItem.key) && menuItem.key !="none"}
                                >
                                    {menuItem.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Dialog
                        open={moduleConfirmPopup}
                        onClose={handleConfirmPopupClose}
                        PaperProps={{ sx: { width: '350px' } }}
                    >
                        <DialogTitle className="confirmDialogTitle">
                         Module Change Confirmation
                            <IconButton aria-label="close" onClick={handleConfirmPopupClose}>
                                <CancelIcon />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent className="confirmDialogContent">
                            <Alert severity="warning" className="confirmDialogMsg">
                              You are setting "None" and about to disable this module for everyone. Do you wish to continue?"
                            </Alert>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleConfirmPopupClose} variant="outlined">
                                Cancel
                            </Button>
                            <Button onClick={handleConfirmPopupContinue} variant="contained">
                                Yes
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <FormControlLabel style={{marginTop:'10px'}}
                        control={
                            <Switch
                                checked={activeStatus}
                                onChange={(e) => setActiveStatus(e.target.checked)}
                                color="primary"
                                size="small"
                            />
                        }
                        label={<Typography className="status-switch">{activeStatus ? STATUS.ACTIVE : STATUS.INACTIVE}</Typography>}
                    />
                {loading && <LinearProgress className='progress-loading'/>}
                </DialogContent>
                <DialogActions className='dialogActions'>
                    <Button onClick={handleCloseModal} variant="outlined">
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit} variant="contained" color="primary" disabled={!isCheck || !!nameError}>
                        {isUpdate ? 'Update' : 'Save'}
                    </Button>
                </DialogActions>
            </Dialog>
            <NotificationContainer />
        </>
    );
};

export default ModulePopup;