import React, { useState, useEffect } from 'react';
import { DataGrid, GridActionsCellItem, GridRowModes, GridToolbarContainer, GridRowEditStopReasons, } from '@mui/x-data-grid';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon, Save as SaveIcon, Cancel as CancelIcon, ImportExport as ImportExportIcon , GetApp as GetAppIcon } from '@mui/icons-material';
import { addHoliday, updateHoliday, deleteHoliday, fetchHolidaysByYear , fetchDistinctYears } from '../../services/HolidayServices';
import { parseISO } from 'date-fns';
import { Alert, Box, Button, Dialog, DialogTitle, DialogContent, DialogActions, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, useTheme,  } from '@mui/material';
import { NotificationManager } from 'react-notifications';
import { format } from 'date-fns';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import moment from 'moment';
import Info from '../../assets/icons/info_icon.png'; 
import logo from '../../assets/logos/phyelements_logo.png';
import HolidayPopup from './HolidayPopup';


const randomId = () => Math.random().toString(36).substring(2, 15);

function EditToolbar({ setRows, setRowModesModel, rows, selectedMonth, 
                        handleMonthChange, handleImportClick, currentYear, 
                        handleYearChange, nextFiveYears, fetchHolidays,filteredHolidays }) {
 const theme = useTheme();  
 const months = moment.months(); 


  const handleClick = () => {
    const id = randomId();
    const newHoliday = { 
      _id: id, 
      holidayName: null, 
      date: null, 
      holidayType: 'Public Holiday', 
      isNew: true 
    };

    setRows(prevRows => [
      ...prevRows,
      newHoliday
    ]);

    setRowModesModel(prevModel => ({
      ...prevModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'holidayName' },
    }));
  };

  const preloadImage = (src) => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = src;
        img.onload = () => resolve(img);
        img.onerror = (err) => reject(err);
    });
  };

  const exportToPDF = async () => {
    const doc = new jsPDF();
    try {
      const img = await preloadImage(logo);
      const infoIconImage = await preloadImage(Info);

      doc.addImage(img, 'PNG', 13, 10);

      const columns = [
          { title: 'Occasion', dataKey: 'holidayName' },
          { title: 'Date', dataKey: 'date' },
          { title: 'Holiday Type', dataKey: 'holidayType' }
      ];

      const data = rows.map(row => ({
          holidayName: row.holidayName,
          date: format(row.date, 'dd-MM-yyyy'),
          holidayType: row.holidayType,
      }));

      const headerTextY = 40;
      const infoIconX = 14;
      const infoTextX = infoIconX + 4;
      const tableStartY = headerTextY + 10;

      const headerText = `Annual Holidays - ${currentYear}`;
      doc.text(headerText, 13, headerTextY);

      doc.addImage(infoIconImage, 'PNG', infoIconX, headerTextY + 3.5, 3, 3);
      doc.setFont("helvetica", "italic");
      const infoText = "(Some holidays may not be applicable to employees working at client locations. Please refer to the client's holiday calendar.)";
      doc.setFontSize(8);
      doc.text(infoText, infoTextX, headerTextY + 6);
      doc.setFont("helvetica", "normal");

      doc.autoTable({
          columns,
          body: data,
          margin: { top: tableStartY },
          headStyles: {
              fillColor: theme.palette.primary.main,
          },
      });

      doc.save(`Phyelements_Holidays_${currentYear}.pdf`);
      NotificationManager.success('Holiday downloaded successfully');
    } catch {
        console.error('Error generating PDF:', error);
        NotificationManager.error('Failed to download holidays');
    }
  }; 


  return (
  <GridToolbarContainer sx={{ height: { xs: '150px', sm: '100px', md: '60px' }, borderBottom: '1px solid lightgray', paddingBottom: '10px' }}>
    <Grid container rowGap={2}>
      <Grid item xs={12} sm={12} md={6.6} sx={{ display: "flex", justifyContent: "flex-start", gap:{xs:'25px', md:'20px'} }}>
      <FormControl sx={{ width: { xs: "48%", sm: '22%', md: '30%' } }} size="small">
      <InputLabel id="month-select-label">Filter by Month</InputLabel>
      <Select
        labelId="month-select-label"
        value={selectedMonth}
        label="Filter by Month"
        onChange={handleMonthChange}
      >

        <MenuItem value={0}>All</MenuItem>
        {months.map((month, index) => (
          <MenuItem value={index + 1}>  
            {month}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
        <FormControl sx={{ width: { xs: "45%", sm: '22%', md: '30%' } }} size="small">
          <InputLabel id="year-select-label">Filter by Year</InputLabel>
          <Select
            labelId="year-select-label"
            value={currentYear}
            label="Filter by Year"
            onChange={handleYearChange}
          >
            {nextFiveYears.map((year) => (
              <MenuItem key={year} value={year}>
                {year}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={4} md={2.1} lg={1.8} sx={{ display: 'flex', justifyContent: { xs: 'center', sm: 'flex-start', md: 'flex-end' } }}>
        <Button
          variant="contained"
          color="primary"
          sx={{ width: { xs: '100%', sm: '95%', md: '100%' }, whiteSpace: 'nowrap'}}
          startIcon={<ImportExportIcon />}
          disabled={!(selectedMonth === 0 || selectedMonth === '')}
          onClick={handleImportClick}
        >
          Import Holiday List
        </Button>
      </Grid>

      <Grid item xs={6} sm={4} md={1.75} lg={1.8} sx={{ display: 'flex', justifyContent: { xs: 'flex-start', sm: 'flex-start', md: 'flex-end' } }}>
        <Button
          variant="contained"
          color="primary"
          sx={{ width: { xs: '95%', sm: '90%', md:'90%' }, whiteSpace: 'nowrap'}}
          startIcon={<AddIcon />}
          disabled={!(selectedMonth === 0 || selectedMonth === '')}
          onClick={handleClick}
        >
          Add Holiday
        </Button>
      </Grid>

      <Grid item xs={6} sm={4} md={1.55} lg={1.8} sx={{ display: 'flex', justifyContent: { xs: 'flex-end', sm: 'flex-start', md: 'flex-end' }}}>
        <Button
          variant="contained"
          color="primary"
          sx={{ width: { xs: '90%', sm: '90%', md:'90%' }, whiteSpace: 'nowrap'}}
          startIcon={<GetAppIcon />}
          disabled={filteredHolidays.length === 0 || !(selectedMonth === 0 || selectedMonth === '')}
          onClick={exportToPDF}
        >
          Download
        </Button>
      </Grid>
    </Grid>
  </GridToolbarContainer>
  );
}

const ManageHolidays = () => {
  const theme = useTheme();
  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(0);
  const [selectedHolidayName, setSelectedHolidayName] = useState('');
  const [popupOpen, setPopupOpen] = useState(false);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [existingYears, setExistingYears] = useState([]);
  const [targetYear, setTargetYear] = useState(new Date().getFullYear())
  const nextFiveYears = Array.from({ length: 5 }, (_, index) => targetYear + index);

  useEffect(() => {
    fetchHolidays(currentYear);
  }, [currentYear]);

  const fetchHolidays = async (year) => {
    try {
      const holidaysResponse = await fetchHolidaysByYear(year);
      if(holidaysResponse.length > 0){
        const holidaysWithDates = holidaysResponse.map(holiday => ({
          ...holiday,
          date: parseISO(holiday.date),
        }));
        const sortedHolidays = holidaysWithDates.sort((a, b) => a.date - b.date);
        setRows(sortedHolidays);
      } 
    } catch (error) {
      console.error('Error fetching holidays:', error);
    }
  };

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = id => () => {
    setRowModesModel(prevModel => ({ ...prevModel, [id]: { mode: GridRowModes.Edit } }));
  };

  const handleSaveClick = id => () => {
    setRowModesModel(prevModel => ({ ...prevModel, [id]: { mode: GridRowModes.View } }));
  };

  const handleDeleteClick = (id) => () => {
    const holidayToDelete = rows.find(row => row._id === id);
    setSelectedHolidayName(holidayToDelete?.holidayName || '');
    setSelectedId(id);
    setOpenDialog(true);
  };
  
  const handleCancelDialog = () => {
    setOpenDialog(false);
    setSelectedId(null);
  };

  const handleConfirmDelete = async () => {
    try {
      await deleteHoliday(selectedId);
      setRows(prevRows => prevRows.filter(row => row._id !== selectedId));
      NotificationManager.success(`Holiday ${selectedHolidayName} deleted successfully`);
    } catch (error) {
      console.error('Error deleting holiday:', error);
      NotificationManager.error('Failed to delete holiday.');
    }
    setOpenDialog(false);
    setSelectedId(null);
  };

  const handleCancelClick = id => () => {
    setRowModesModel(prevModel => ({
      ...prevModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    }));

    const editedRow = rows.find(row => row._id === id);
    if (editedRow && editedRow.isNew) {
      setRows(prevRows => prevRows.filter(row => row._id !== id));
    }
  };

  const processRowUpdate = async (newRow) => {
    
    try {
      if (!newRow.holidayName || newRow.holidayName.trim() === '') {
        NotificationManager.error('Occasion cannot be empty');
        return ;
      }
      
      if (!newRow.date) {
        NotificationManager.error('Holiday Date cannot be an empty.');
        return;
      }
  
      // Check for duplicate holiday name
      const isDuplicateName = rows.some(
        (row) => 
          row.holidayName?.trim().toLowerCase() === newRow.holidayName.trim().toLowerCase() &&
          row._id !== newRow._id // Ignore the same row being updated
      );

      if (isDuplicateName) {
        NotificationManager.error(`Holiday name "${newRow.holidayName}" already exists.`);
        return;
      }

      const isDuplicateDate = rows.some(
        (row) =>
          row.date?.toDateString() === newRow.date.toDateString() &&
          row._id !== newRow._id
      );
       
      if (isDuplicateDate) {
        NotificationManager.error(`Holiday date "${newRow.date.toLocaleDateString()}" already exists.`);
        return;
      }

      const updatedRow = { ...newRow };
  
      if (newRow.isNew) {
        const { _id, ...newRowData } = updatedRow;
        const newHoliday = await addHoliday(newRowData);
        const finalUpdatedRow = { ...updatedRow, _id: newHoliday._id, isNew: false };
        setRows(prevRows => prevRows.map(row => (row._id === newRow._id ? finalUpdatedRow : row)));
        NotificationManager.success('Holiday added successfully!');
        return finalUpdatedRow;
      } else {
        await updateHoliday(updatedRow._id, updatedRow);
        setRows(prevRows =>
          prevRows.map(row => (row._id === updatedRow._id ? updatedRow : row))
        );
        NotificationManager.success('Holiday updated successfully!');
        return { ...updatedRow, isNew: false };
      }
    } catch (error) {
      console.error('Error updating holiday:', error);
      NotificationManager.error(error.response?.data?.message || 'An error occurred');
      return;
    }
  };

  const handleRowModesModelChange = newRowModesModel => {
    setRowModesModel(newRowModesModel);
  };

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const handleYearChange = (event) => {
    setCurrentYear(event.target.value);
  };

  const fetchExistingYears = async () => {
    try {
        const years = await fetchDistinctYears();
        setExistingYears(years);
        
    } catch (error) {
    }
  };
  
  const handleImportClick = () => {
    fetchExistingYears();
    setPopupOpen(true);
  };

  const handleClosePopup = () => {
    setPopupOpen(false);
  };

  const filteredHolidays = rows.filter(row => {
    const date = new Date(row.date);
    const monthMatches = !selectedMonth || date.getMonth() === selectedMonth - 1;
    const yearMatches = !currentYear || date.getFullYear() === currentYear;
    const isNewRow = row.isNew;
    
    return (monthMatches && yearMatches) || isNewRow;
  });
  
  const columns = [
    { field: 'holidayName', headerName: <strong>Occasion</strong>, flex: 4, minWidth: 200, editable: true },
    {
      field: 'date', headerName: <strong>Date</strong>, type: 'date', flex: 2.5, minWidth: 200, editable: true,
      renderCell: (params) => format(new Date(params.value), 'dd-MM-yyyy'),
    },
    { field: 'holidayType', headerName: <strong>Holiday Type</strong>, flex: 2.5, minWidth: 200, editable: true, type: 'singleSelect',
      valueOptions: ['Public Holiday', 'Restricted Holiday'] 
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: <strong>Actions</strong>,
      flex: 1, minWidth: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        return isInEditMode
          ? [
              <GridActionsCellItem
                key={`save-${id}`}
                icon={<SaveIcon />}
                label="Save"
                color="inherit"
                onClick={handleSaveClick(id)}
                title="Save"
              />,
              <GridActionsCellItem
                key={`cancel-${id}`}
                icon={<CancelIcon />}
                label="Cancel"
                className="textPrimary"
                onClick={handleCancelClick(id)}
                color="inherit"
                title="Cancel"
              />,
            ]
          : [
              <GridActionsCellItem
                key={`edit-${id}`}
                icon={<EditIcon />}
                label="Edit"
                className="textPrimary"
                onClick={handleEditClick(id)}
                color="inherit"
                title="Edit"
              />,
              <GridActionsCellItem
                key={`delete-${id}`}
                icon={<DeleteIcon />}
                label="Delete"
                onClick={handleDeleteClick(id)}
                color="inherit"
                title="Delete"
              />,
            ];
      },
    },
  ];

  return (
    <Box className = 'main-content'>
        <Grid className='grid-container-header grid-container-header-with-nofilters align-header-center'>
          Manage Holidays
        </Grid>

        <Grid container padding={0.5} style={{ backgroundColor: '#fff'}}>
          <Grid item xs={12}>
            <DataGrid
              localeText={{ noRowsLabel: "No Holidays to show." }}
              rows={filteredHolidays}
              columns={columns}
              getRowId={(row) => row._id}
              editMode="row"
              rowModesModel={rowModesModel}
              onRowModesModelChange={handleRowModesModelChange}
              onRowEditStop={handleRowEditStop}
              processRowUpdate={processRowUpdate}
              slots={{
                toolbar: EditToolbar,
              }}
              slotProps={{
                toolbar: { setRows, setRowModesModel, rows, selectedMonth, handleMonthChange, handleImportClick, currentYear, handleYearChange, nextFiveYears, fetchHolidays, filteredHolidays },
              }}
              autoHeight
              hideFooterPagination
              sx={{
                '& .MuiDataGrid-selectedRowCount': {
                  display: 'none',
                },
              }}
            />
          </Grid>
        </Grid>
      <Dialog
        open={openDialog}
        onClose={handleCancelDialog}
      >
        <DialogTitle className="confirmDialogTitle"> Delete Holiday Confirmation <IconButton aria-label="close" onClick={handleCancelDialog}><CancelIcon /></IconButton></DialogTitle>
        <DialogContent className='confirmDialogContent'>
          <Alert severity='warning' className='confirmDialogMsg'>
            Are you sure you want to delete the holiday <strong>{selectedHolidayName}</strong>?
          </Alert>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' onClick={handleCancelDialog} color="primary">
            Cancel
          </Button>
          <Button variant='contained' onClick={handleConfirmDelete} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <HolidayPopup open={popupOpen} handleClose={handleClosePopup} refreshHolidayList={() => fetchHolidays(currentYear)} existingYears={existingYears}/>
    </Box>
  );
};

export default ManageHolidays;
