import {Alert, Box, Button, DialogTitle, Grid, IconButton,Dialog, 
        DialogActions, DialogContent, Typography, TextField,} from '@mui/material'
import {Cancel as CancelIcon } from '@mui/icons-material';
import React, { useState, useEffect} from 'react'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { NotificationManager } from "react-notifications";
import "./ITDeclaraton.css"
import { saveRegime, checkRegimeExistence  } from '../../services/itDeclarationService';
import { jsonData } from '../../JsonTemplate';

export default function ITDeclaration() {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedRegime, setSelectedRegime] = useState('');
  const [isSaved, setIsSaved] = useState(false);
  const employeeId = localStorage.getItem("employeeId");
  const [financialYear, setFinancialYear] = useState('');
  const [declaredAmounts, setDeclaredAmounts] = useState({});
  const [originalData, setOriginalData] = useState([]);
  const [items, setItems] = useState([]);
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState();

  useEffect(() => {
    fetchRegime();
    setFinancialYear(getFinancialYear())
  }, []);

  // const fetchRegime = async () => {
  //   try {
  //     const result = await checkRegimeExistence(employeeId);
  //     if (result) {
  //       // If a regime exists, set the selected regime and disable further selection
  //       setSelectedRegime(result.regime);
  //       setIsSaved(true);
  //       setOriginalData(result)
  //       // setItems(result.length ? result.oldRegimeDetails : jsonData.items)
  //       console.log("result length", result)
  //       setItems(result.oldRegimeDetails)
  //       // write a logic to update "declaredAmounts" 
  //       const newDeclaredAmounts = {};
  //       result.oldRegimeDetails.items.forEach(section => {
  //         Object.keys(section).forEach(categoryKey => {
  //           section[categoryKey].forEach((entry, index) => {
  //             if (entry.DECLARED_AMOUNT) {
  //               newDeclaredAmounts[`${categoryKey}-${index}`] = entry.DECLARED_AMOUNT;
  //             }
  //           });
  //         });
  //       });
  //       setDeclaredAmounts(newDeclaredAmounts);
  //     } else {
  //       // If no regime exists, allow selecting a regime
  //       // setSelectedRegime('');
  //       setItems(jsonData.items)
  //       setIsSaved(false);
  //     }
  //   } catch (error) {
  //     setIsSaved(false);
  //   }
  // };
    
  const fetchRegime = async () => {
    try {
      const result = await checkRegimeExistence(employeeId);
      if (result) {
        setSelectedRegime(result.regime);
        setIsSaved(true);
        setOriginalData(result);
        if (result.regime == "New Regime") {
          setIsSaveButtonDisabled(true);
        }

        // Update items and declaredAmounts if applicable
        if (result.oldRegimeDetails) {
          setItems(result.oldRegimeDetails);
          const newDeclaredAmounts = {};
          result.oldRegimeDetails.items?.forEach((section) => {
            Object.keys(section).forEach((categoryKey) => {
              section[categoryKey].forEach((entry, index) => {
                if (entry.DECLARED_AMOUNT) {
                  newDeclaredAmounts[`${categoryKey}-${index}`] = entry.DECLARED_AMOUNT;
                }
              });
            });
          });
          setDeclaredAmounts(newDeclaredAmounts);
        }
      } else {
        // No data found, reset state
        setItems(jsonData.items);
        // setIsSaved(false);
      }
    } catch (error) {
      console.error("Error fetching regime data:", error);
    }
  };
  
  const getFinancialYear = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const month = currentDate.getMonth();

    if (month >= 0 && month <= 2) {
      return `${currentYear - 1}-${currentYear.toString().slice(-2)}`
    }
    return `${currentYear}-${(currentYear + 1).toString().slice(-2)}`;
  };


  const handleCancel = () => {
    setIsDialogOpen(false);
    setSelectedRegime('');
  };

  const handleProceed = () => {
    setIsDialogOpen(false);
    setIsSaved(true)
  }

  const handlePopupOpen = () => {
    if (!isSaved) {
      setIsDialogOpen(true);
    }
  }

  const handleRegimeSave = async () => {
    try {
      if (selectedRegime == "Old Regime") {
        await saveRegime(employeeId, selectedRegime, financialYear, items);
        NotificationManager.success('Regime saved successfully');
        setIsSaved(true);
      }
      else {
        await saveRegime(employeeId, selectedRegime, financialYear);
        NotificationManager.success('Regime saved successfully');
        setIsSaveButtonDisabled(true);
        setIsSaved(true);
        setIsDialogOpen(false)
      }
    } catch (error) {
      NotificationManager.error('Failed to save regime');
      setIsDialogOpen(false);
    }
  }

  const handleRegimeChange = (event) => {
    setSelectedRegime(event.target.value);
  };

  const renderTableRows = () => {
    // return Object.keys(items).map((sectionKey, sectionIndex) => {
    //   const section = items[sectionKey];
    //   console.log("column length", Object.keys(section[Object.keys(section)[0]][0]).length)
    //   return (
    //     <>
    //       {/* Section Header Row */} 
    //       <tr style={{ backgroundColor: "#d9eaf7", fontWeight: "bold" }}>
    //         <td colSpan={Object.keys(section[Object.keys(section)[0]][0]).length + 1} style={{ textAlign: "center"}}>
    //           {sectionKey}
    //         </td>
    //       </tr>
    //       {Object.keys(section).map((categoryKey, categoryIndex) => {
    //         // const category = section[categoryKey].map((entry)=>({
    //         //     ...entry,
    //         //     DECLARED_AMOUNT: declaredAmount,
    //         // }))
    //         const category = section[categoryKey];
    //         return category.map((entry, entryIndex) => (
    //           <tr key={`${sectionIndex}-${categoryIndex}-${entryIndex}`} style={{ backgroundColor:"transparent" }}>
    //             {entryIndex === 0 && (
    //               <td
    //                 rowSpan={category.length}
    //               >
    //                 {categoryKey}
    //               </td>
    //             )}
    //             <td >{entry.PARTICULARS}</td>
    //             <td>{entry.MAXIMUM_LIMIT}</td>
    //             {/* <td >{entry.DECLARED_AMOUNT="100"}</td> */}
    //             <td>
    //             {Object.keys(entry).map((propertyKey) => (
    //                     <input
    //                     key={propertyKey}
    //                     type="number"
    //                     value={declaredAmounts[`${categoryKey}-${propertyKey}`] || ''}
    //                     onChange={(e) => setDeclaredAmounts({
    //                         ...declaredAmounts,
    //                         [`${categoryKey}-${propertyKey}`]: e.target.value,
    //                     })}
    //                     placeholder={`Enter declared amount for ${propertyKey}`}
    //                     />
    //                 ))}
    //             </td>
    //           </tr>

    //         ));
    //       })}
    //     </>
    //   );
    // });

    return Object.keys(items).map((sectionKey, sectionIndex) => {
      const section = items[sectionKey];
      // console.log("section", section);
      // console.log("column length", Object.keys(section[Object.keys(section)[0]][0]).length);
      return (
        <>
          {/* Section Header Row */}
          <tr style={{ backgroundColor: "#d9eaf7", fontWeight: "bold" }}>
            <td colSpan={Object.keys(section[Object.keys(section)[0]][0]).length + 1} style={{ textAlign: "center" }}>
              {sectionKey}
            </td>
          </tr>
          {Object.keys(section).map((categoryKey, categoryIndex) => {
            // console.log("categoryKey:",categoryKey,"categoryIndex:",categoryIndex)
            const category = section[categoryKey];
            // console.log("category",category)
            return category.map((entry, entryIndex) => (
              <tr key={`${sectionIndex}-${categoryIndex}-${entryIndex}`} style={{ backgroundColor: "transparent" }}>
                {

                  entryIndex === 0 && (
                    <td
                      rowSpan={category.length}
                    >
                      {categoryKey}
                    </td>
                  )}
                {Object.keys(entry).map((propertyKey) => (
                  <td key={propertyKey}>
                    {propertyKey !== 'DECLARED_AMOUNT' ? entry[propertyKey] : (
                      <TextField
                        size='small'
                        style={{width:"180px"}}
                        value={declaredAmounts[`${categoryKey}-${propertyKey}-${entryIndex}`] || entry.DECLARED_AMOUNT}
                        onChange={(e) => {
                          const value = e.target.value;
                          const numericRegex = /^[0-9]*\.?[0-9]*$/; 
                    
                          if (numericRegex.test(value)) {
                            setDeclaredAmounts({
                              ...declaredAmounts,
                              [`${categoryKey}-${propertyKey}-${entryIndex}`]: value,
                            });
                            category[entryIndex].DECLARED_AMOUNT = value;
                          }
                        }}
                      />
                    )}
                  </td>
                ))}
              </tr>
            ));
          })}
        </>
      );
    })

  };
      
  return (
    <Box className = 'main-content'>            
    <Grid container className='grid-container-header align-header-center'>
        <Grid item xs={12}>
          IT Declaration
        </Grid>
    </Grid>
    <Grid container className="it-declaration-container">
        <Grid item xs={12}>
        <FormControl>
        <Typography><strong>Select the regime for the financial year {financialYear} :</strong></Typography>
            <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                value={selectedRegime}
                onChange={handleRegimeChange}
                className='radio-group'
            >
                    <FormControlLabel  
                    className="radio-small" 
                    value="New Regime" 
                    control={<Radio/>} 
                    label="New Regime"  
                    disabled={isSaved && selectedRegime !== 'New Regime'}
                    onClick={handlePopupOpen}/>
                    
                    <FormControlLabel  
                    className="radio-small" 
                    value="Old Regime" 
                    control={<Radio/>} 
                    label="Old Regime" 
                    disabled={isSaved && selectedRegime !== 'Old Regime'}  
                    onClick={handlePopupOpen} />
            </RadioGroup>
        </FormControl>              
        </Grid>
        <Grid item xs={12}>
             {(selectedRegime === 'Old Regime')  && (

                <table>
                    <thead>
                        <tr>
                            <th>ITEMS</th>
                            <th>PARTICULARS</th>
                            <th>MAXIMUM LIMIT</th>
                            <th>DECLARED AMOUNT</th>
                        </tr>
                    </thead>
                    <tbody>
                      {originalData && 
                        renderTableRows()
                      }
                    </tbody>
                </table>
            )}
        </Grid>
        <Grid item xs={12} className='align-itdeclaration-buttons-right'>
          {(selectedRegime === 'Old Regime') && (<Button variant="contained" onClick={handleRegimeSave}
            disabled={!selectedRegime || isSaveButtonDisabled}
          >
            Save
          </Button>)}
        </Grid>
    </Grid>
    
    <Dialog
    open={isDialogOpen}
    onClose={handleCancel}
    >
        <DialogTitle className= 'confirmDialogTitle'>
            Regime selection Confirmation
            <IconButton aria-label="close" onClick={handleCancel}>
            <CancelIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent className='confirmDialogContent'>

          <Alert severity="warning" className="confirmDialogMsg">
            You're about to set a {selectedRegime}.
            {selectedRegime === "Old Regime" && (
              <strong> You need to provide some details below.</strong>
            )}
            This change cannot be reversed for the current financial year ({financialYear}).
             Are you sure you want to {selectedRegime === "New Regime" ? "save" : "proceed"}?
          </Alert>

        </DialogContent>
        <DialogActions>
            <Button onClick={handleCancel} variant="outlined" color="primary">
            Cancel
            </Button>

            <Button 
            onClick={selectedRegime === 'New Regime' ? handleRegimeSave : handleProceed} 
            variant="contained" color="primary"> 
            {selectedRegime === 'New Regime' ? 'Save' : 'Proceed'} 
            </Button>
        </DialogActions>
    </Dialog>
</Box>
  )
}
