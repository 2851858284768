import React, { createContext, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../Constants';
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const navigate = useNavigate();
    const [userToken, setUserToken] = useState(() => {
        return sessionStorage.getItem('userToken') || null;
    });
    const [user, setUser] = useState(null);

    React.useEffect(() => {
        const checkTokenValidity = async () => {
            if (userToken) {
                const { isValid, user } = await verifyTokenOnBackend(userToken);
                if (isValid) {
                    sessionStorage.setItem('userToken', userToken);
                    setUser(user);
                } else {
                    sessionStorage.removeItem('userToken');
                    navigate("/");
                    setUser(null);
                    console.error('Invalid token or expired.');
                }
            } else {
                sessionStorage.removeItem('userToken');
                setUser(null);
            }
        };

        checkTokenValidity();
    }, [userToken]);

    const isAuthenticated = !!userToken; 

   
    const verifyTokenOnBackend = async (token) => {
        try {
            const response = await fetch(`${BASE_URL}/auth/isvalidtoken`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            if (response.ok) {
                const data = await response.json();
                return { isValid: true, user: data.user };
            }
            return { isValid: false, user: null };
        } catch (error) {
            console.error('Error verifying token on backend:', error);
            return false; 
        }
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, setUserToken }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);

