import React, { useState, useRef } from "react";
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

const ImageCrop = ({ openCropModal, setOpenCropModal, setCroppedImageUrl, src }) => {
  const [crop, setCrop] = useState({
    unit: "%",
    width: 30,
    height: 30,
      x: (100 - 30) / 2, // Center horizontally
      y: (100 - 30) / 2, // center vertically
    // aspect: 1 / 1,  // Maintain square aspect ratio
  });
  const [croppedBlob, setCroppedBlob] = useState(null);
  const imageRef = useRef(null);

  const onImageLoaded = (image) => {
    imageRef.current = image;
  };

  const onCropChange = (crop) => {
    setCrop(crop);
  };

  const onCropComplete = async (crop) => {
    if (imageRef.current && crop.width && crop.height) {
      const blob = await getCroppedImg(imageRef.current, crop, "cropped.jpg");
      setCroppedBlob(blob); 
    }
  };

  const getCroppedImg = (image, crop, fileName) => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve) => {
      canvas.toBlob(
        (blob) => {
          blob.name = fileName;
          resolve(blob);
        },
        "image/jpeg",
        1
      );
    });
  };

  const handleApply = () => {
    if (croppedBlob) {
      setCroppedImageUrl(croppedBlob);
      setOpenCropModal(false); 
    }
  };

  return (
    <Dialog
      open={openCropModal}
      onClose={() => setOpenCropModal(false)}
      aria-labelledby="crop-dialog-title"
    >
      <DialogTitle id="crop-dialog-title">Crop Picture</DialogTitle>
      <DialogContent>
        {src ? (
          <ReactCrop
            src={src}
            crop={crop}
            onImageLoaded={onImageLoaded}
            onComplete={onCropComplete}
            onChange={onCropChange}
          />
        ) : (
          <p>No image selected</p>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => setOpenCropModal(false)}
          variant="contained"
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleApply}
          disabled={!croppedBlob}
        >
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ImageCrop;
