import React, { useState, useEffect } from 'react';
import { Grid, Box, FormControl, Select, MenuItem, InputLabel , useTheme,LinearProgress } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { format, addDays, startOfMonth, endOfMonth, isWeekend, isBefore, isSameDay } from 'date-fns';
import moment from 'moment';
import { fetchWeeklyReports } from '../../services/weeklyReportServices';
import { getAllEmployeeNames } from '../../services/employeeService';

import { SvgIcons } from '../../assets/icons/SvgIcons';
import { WEEKLY_REPORT_STATUS } from '../../Constants';

export default function ViewWeeklyReports() {
    const theme = useTheme();
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    const [selectedMonth, setSelectedMonth] = useState(currentMonth);
    const [selectedWeek, setSelectedWeek] = useState('');
    const [selectedYear, setSelectedYear] = useState(currentYear);
    const [originalData, setOriginalData] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [selectedEmployee, setSelectedEmployee] = useState('');
    const months = moment.months(); 

    useEffect(() => {
        const weeksForNewMonth = getWeeksInMonth(selectedYear, selectedMonth);
        const currentWeekIndex = weeksForNewMonth.findIndex(week =>
            isBefore(week.start, new Date()) && isBefore(new Date(), addDays(week.end, 1))
        );
        if (currentWeekIndex >= 0) {
            setSelectedWeek(currentWeekIndex);
            handleWeekChange({ target: { value: currentWeekIndex } });
        }
    }, [selectedMonth, selectedYear]);
    
    useEffect(()=>{
        getEmployees();
    },[]);

    useEffect(() => {
        fetchReports();
    }, [selectedWeek, selectedYear, selectedMonth, selectedEmployee]); 

    const fetchReports = async () => {
        if(!selectedEmployee){
            return;
        }
        const weekDetails = weeksInMonth[selectedWeek];
        const startDate = startOfMonth(new Date(selectedYear, selectedMonth));
        const endDate = endOfMonth(new Date(selectedYear, selectedMonth));

        try {
            // If "All" is selected, fetch the entire month's reports
            const reports = selectedWeek === 'All' 
                ? await fetchWeeklyReports(selectedEmployee, selectedYear, format(startDate, 'yyyy-MM-dd'), format(endDate, 'yyyy-MM-dd')) 
                : await fetchWeeklyReports(selectedEmployee, selectedYear, format(weekDetails.start, 'yyyy-MM-dd'), format(weekDetails.end, 'yyyy-MM-dd'));

            setOriginalData(reports);

        } catch (error) {
            console.error('Error fetching weekly reports:', error);
        }
    };

    const getEmployees = async () => {
        try {
            const data = await getAllEmployeeNames();
            setEmployees(data);
        } catch (error) {
            console.error('Error fetching employees:', error);
        }
    };

    const handleEmployeeChange = (event) => {
        setSelectedEmployee(event.target.value);
    };

    const handleYearChange = (event) => {
        const newYear = event.target.value;
        setSelectedYear(newYear);
    
        // Set month to January (0)
        const newMonth = 0;
        setSelectedMonth(newMonth);
    
        // Recalculate weeks based on new year and month
        const weeksForNewMonth = getWeeksInMonth(newYear, newMonth);
    
        // Set the first week as the selected week
        if (weeksForNewMonth.length > 0) {
            setSelectedWeek(0);
        }
    };   

    const handleMonthChange = (event) => {
        const newMonth = event.target.value;
        setSelectedMonth(newMonth);
    
        const weeksForNewMonth = getWeeksInMonth(selectedYear, newMonth);
    
        // If there are weeks available, set the first week as the selected week
        if (weeksForNewMonth.length > 0) {
            setSelectedWeek(0);
        }
    };

    const handleWeekChange = (event) => {
        setSelectedWeek(event.target.value);
    };
    
    const getWeeksInMonth = (year, month) => {
        const start = startOfMonth(new Date(year, month));
        const end = endOfMonth(new Date(year, month));
        let weeks = [];
        let currentDay = start;
        let weekStart = null;

        while (isBefore(currentDay, end) || isSameDay(currentDay, end)) {
            if (!isWeekend(currentDay)) {
                if (!weekStart) {
                    weekStart = currentDay;
                }

                const isFriday = currentDay.getDay() === 5;
                const isLastDayOfMonth = isSameDay(currentDay, end);

                if (isFriday || isLastDayOfMonth) {
                    const days = [];
                    let tempDay = weekStart;
                    while (isBefore(tempDay, addDays(currentDay, 1))) {
                        if (!isWeekend(tempDay)) days.push(tempDay);
                        tempDay = addDays(tempDay, 1);
                    }
                    weeks.push({
                        start: weekStart,
                        end: currentDay,
                        days,  // Store the weekdays
                    });
                    weekStart = null;
                }
            }
            currentDay = addDays(currentDay, 1);
        }

        return weeks;
    };

    const weeksInMonth = getWeeksInMonth(selectedYear, selectedMonth);

    const columns = [
        { field: 'date', headerName: <strong>Date</strong>, flex: 1,
            valueGetter: (params) => {
                return format(new Date(params), 'dd-MM-yyyy');
            }
         },
        { field: 'topic', headerName: <strong>Topic</strong>, flex: 2, minWidth: 100 },
        { field: 'description', headerName: <strong>Description</strong>, flex: 3, minWidth: 200 },
        { field: 'status', headerName: <strong>Status</strong>, flex: 1, minWidth: 100,
            renderCell: (params) => (
                <span>
                    {params.value === WEEKLY_REPORT_STATUS.IN_PROGRESS ? (
                        <SvgIcons marginTop="5px" fillColor={WEEKLY_REPORT_STATUS.IN_PROGRESS_COLOR} text={WEEKLY_REPORT_STATUS.IN_PROGRESS} />
                    ) : params.value === WEEKLY_REPORT_STATUS.COMPLETED ? (
                        <SvgIcons marginTop="5px" fillColor={WEEKLY_REPORT_STATUS.COMPLETED_COLOR} text={WEEKLY_REPORT_STATUS.COMPLETED} />
                    ) : null}
                </span>
            )
         }
    ];

    return (
        <Box className = 'main-content'>
                <Grid container className='grid-container-header align-header-center'>
                    <Grid item xs={3}>
                        Employee's Weekly Report
                    </Grid>
                    <Grid container xs={9} className='align-filters-right'>
                        <FormControl variant="outlined" size="small">
                            <InputLabel>Employee</InputLabel>
                            <Select value={selectedEmployee} label="Employee" onChange={handleEmployeeChange} sx={{ minWidth: '100px' }}>
                                {employees.map((employee) => (
                                    <MenuItem key={employee._id} value={employee._id}>
                                        {employee.firstName} {employee.lastName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl variant="outlined" size="small">
                            <InputLabel>Year</InputLabel>
                            <Select value={selectedYear} label="Year" onChange={handleYearChange} displayEmpty>
                                {[currentYear, currentYear - 1].map((year) => (
                                    <MenuItem key={year} value={year}>
                                        {year}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl variant="outlined" size="small">
                            <InputLabel>Month</InputLabel>
                            <Select value={selectedMonth} label="Month" onChange={handleMonthChange} displayEmpty>
                                {Array.from({ length: 12 }, (_, i) => (
                                    <MenuItem key={i} value={i}>
                                        {months[i]}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl variant="outlined" size="small">
                            <InputLabel>Week</InputLabel>
                            <Select
                                sx={{ maxWidth: "190px" }}
                                value={selectedWeek}
                                onChange={handleWeekChange}
                                label="Week"
                            >
                                <MenuItem value="All">All</MenuItem>
                                {weeksInMonth.map((week, index) => (
                                    <MenuItem key={index} value={index}>
                                        {`Week ${index + 1} (${format(week.start, 'dd/MMM/yyyy')} to ${format(week.end, 'dd/MMM/yyyy')})`}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>

                <Grid container padding={2} style={{ backgroundColor: '#fff', minHeight: 'auto'}}>
                <Grid item xs={12}>
                    <DataGrid
                        localeText={{ noRowsLabel: "No Reports to show." }}
                        rows={originalData}
                        getRowId={(row) => row._id}
                        columns={columns}
                        hideFooterPagination
                        autoHeight
                        components={{
                            LoadingOverlay: LinearProgress,
                        }}
                        sx={{
                            minHeight:"170px",
                            '& .MuiDataGrid-selectedRowCount': {
                              display: 'none',
                            },
                            '& .MuiDataGrid-cell': {
                                display: 'flex',
                                alignItems: 'center',
                                padding: '5px', 
                            },
                        }}
                        getRowHeight={() => 'auto'}   
                    />
                </Grid>
            </Grid>
        </Box>
    );
}
