import React, { useRef, useState, useEffect } from 'react';
import { useMediaQuery ,Box, Typography, IconButton, Card, CardMedia, CardContent,useTheme  } from '@mui/material';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ArrowForwardIos as ArrowForwardIosIcon, ArrowBackIosNew as ArrowBackIosNewIcon, Person2 as Person2Icon} from '@mui/icons-material';
import './dashboard.css';

const EmployeeCarousel = ({ title, employees }) => {
  
  const sliderRef = useRef(null);
  const [employeePhotos, setEmployeePhotos] = useState({});
  const theme = useTheme();
  const isVerySmallScreen = useMediaQuery(theme.breakpoints.only('xs')); 
  const isSmallScreen = useMediaQuery(theme.breakpoints.only('sm')); 
  const isMediumScreen = useMediaQuery(theme.breakpoints.up('sm') && theme.breakpoints.down('md')); 
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md') && theme.breakpoints.down('lg')); 

  const slidesToShow = isVerySmallScreen ? Math.min(employees.length, 1) 
  : isSmallScreen ? Math.min(employees.length, 3) 
  : isMediumScreen ? Math.min(employees.length, 2) 
  : isLargeScreen ? Math.min(employees.length, 2) : Math.min(employees.length, 3);

  useEffect(() => {
    const fetchPhotos = async () => {
      const photos = {};
      for (const employee of employees) {
        // if (employee.photo && employee.photo.fileId) {
        //   try {
        //     const photoResponse = await getFileById(employee.photo.fileId);
        //     photos[employee._id] = photoResponse;
        //   } catch (error) {
        //     console.error(`Failed to fetch photo for employee ${employee._id}:`, error);
        //     photos[employee._id] = null;
        //   }
        // }
        const photoUrl = employee.photo?.base64Data ? `data:${employee.photo.contentType};base64,${employee.photo.base64Data}` : null 
        photos[employee._id] = photoUrl;
      }
      setEmployeePhotos(photos);
    };
    fetchPhotos();
  }, [employees]);

  const settings = {
    autoplay: true,
    autoplaySpeed: 4000,
    infinite: employees.length > 1,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    rtl: true,
  };

  return (
    <Box className="employee-carousel-wrapper">
      <Box style={{backgroundColor:theme.palette.secondary.main}} className="box-wrapper">
        <Typography variant="h4">{title}</Typography>
      </Box>
      
      <IconButton onClick={() => sliderRef.current.slickPrev()} className="carousel-arrow-left">
        <ArrowBackIosNewIcon />
      </IconButton>

      <IconButton onClick={() => sliderRef.current.slickNext()} className="carousel-arrow-right">
        <ArrowForwardIosIcon />
      </IconButton>

      <Slider ref={sliderRef} {...settings}>
        {employees.map((employee) => (
          <Box key={employee._id}  className="employee-wrap-container">
            <Card className="employee-carousel-card-container">
            <CardMedia component={employeePhotos[employee._id] ? "img" : Person2Icon} src={employeePhotos[employee._id] || ""} alt={employee.firstName} className='carousel-media'
            sx={{ fontSize: employeePhotos[employee._id] ? 'auto' : '160px'}}/>
              <CardContent className='carousel-card-content'>
                <Typography variant="h6" className="employee-name"  title={`${employee.firstName} ${employee.lastName}`}>
                  {`${employee.firstName} ${employee.lastName}`}
                </Typography>
              </CardContent>
            </Card>
          </Box>
        ))}
      </Slider>
    </Box>
  );
};

export default EmployeeCarousel;
