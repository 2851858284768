import axiosInstance from './axiosInstance';
import { BASE_URL } from '../Constants';

export const fetchAllHolidays = async () => {
    try {
      const response = await axiosInstance.get(`${BASE_URL}/holidays`);
      return response.data;
    } catch (error) {
      console.error('Error fetching holidays:', error);
      throw error;
    }
};

export const addHoliday = async (newData) => {
    try {
        const response = await axiosInstance.post(`${BASE_URL}/holidays`, newData);
        return response.data;
    } catch (error) {
        console.error(`Error updating holiday`, error);
        throw error;
    }
};

export const updateHoliday = async (id, updatedData) => {
    try {
        const response = await axiosInstance.put(`${BASE_URL}/holidays/${id}`, updatedData);
        return response.data;
    } catch (error) {
        console.error(`Error updating holiday ${id}:`, error);
        throw error;
    }
};

export const deleteHoliday = async (id) => {
    try {
        const response = await axiosInstance.delete(`${BASE_URL}/holidays/${id}`);
        return response.data;
    } catch (error) {
        console.error(`Error deleting holiday ${id}:`, error);
        throw error;
    }
};

export const fetchHolidaysByYear = async (year) => {
    try {
        const response = await axiosInstance.get(`${BASE_URL}/holidays/${year}`);
        return response.data;
    } catch (error) {
        console.error(`Error fetching holidays for the year ${year}:`, error);
        throw error;
    }
};

export const importHolidaysForNewYear = async (fromYear, toYear, replace = false) => {
    try {
        const response = await axiosInstance.post(`${BASE_URL}/holidays/import`, { fromYear, toYear, replace });
        return response.data;
    } catch (error) {
        console.error(`Error importing holidays from ${fromYear} to ${toYear}:`, error);
        throw error;
    }
};

export const fetchDistinctYears = async () => {
    try {
        const response = await axiosInstance.get(`${BASE_URL}/holidays/years`);
        return response.data; 
    } catch (error) {
        console.error('Error fetching distinct years:', error);
        throw error;
    }
};

export const fetchHolidaysByYearFiltered = async (year) => {
    try {
        const response = await axiosInstance.get(`${BASE_URL}/holidays/${year}`);
        const holidays = response.data;

        const currentDate = new Date();
        const upcomingHolidays = holidays.filter(holiday => new Date(holiday.date) >= currentDate);

        return upcomingHolidays.sort((a, b) => new Date(a.date) - new Date(b.date));
    } catch (error) {
        console.error(`Error fetching holidays for the year ${year}:`, error);
        throw error;
    }
};


export const importFromExcel = async (payload) => {
    try {
      const response = await axiosInstance.post(`${BASE_URL}/holidays/importFromExcel`, payload);
      return response.data;
    } catch (error) {
      console.error('Error importing holidays:', error.response ? error.response.data : error.message);
      throw error;
    }
  };
  

