import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Button, Box, FormControl, InputLabel, Select, MenuItem, Checkbox, ListItemText, Grid,FormHelperText  } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import NotificationManager from 'react-notifications/lib/NotificationManager';
import * as XLSX from 'xlsx';
import moment from 'moment';
import { getAllEmployeeNames} from '../../services/employeeService';
import { getTimeTrackerData } from '../../services/TimeTrackerServices';

export default function ExportPopup({open, handleClose }) {
    const months = moment.months(); 
    const currentMonth = months[new Date().getMonth()];
    const currentYear = new Date().getFullYear();
    const years = [currentYear - 1, currentYear, currentYear + 1];
    const [selectedEmployees, setSelectedEmployees] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [month, setMonth] = useState(currentMonth);
    const [year, setYear] = useState(currentYear);
    const [error, setError] = useState('');

    useEffect(() => {
        if(open){
        getEmployess();
        }
    }, []);

    const getEmployess = async () => {
        const data = await getAllEmployeeNames();
        setEmployees(data);
    }

    const handleEmployeeChange = (event) => {
        setSelectedEmployees(event.target.value);
            setError('');
    };

    const handleMonthChange = (event) => {
        setMonth(event.target.value);
            setError('');
    };

    const handleYearChange = (event) => {
        setYear(event.target.value);
            setError('');
    };    

    const getDaysInMonth = (year, month) => {
        const date = new Date(year, month, 1);
        const days = [];
        
        while (date.getMonth() === month) {
            // Get the local date and format it as YYYY-MM-DD
            const localDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000); 
            days.push(localDate.toISOString().split('T')[0]);
            date.setDate(date.getDate() + 1);
        }
    
        return days;
    };    

    const handleExport = async () => {
        try {
            // Fetch the timesheets for selected employees
            const promises = selectedEmployees.map(empId =>
                getTimeTrackerData(empId, year, month)
            );
            const results = await Promise.all(promises);

            if( results.length <= 0){
                setError('No data available for selected criteria.')
                return   
            }
             // Combine the timesheet data for each employee
            const combined = results.map((timesheetData, index) => {
            const employee = employees.find(emp => emp._id === selectedEmployees[index]);

            if (!timesheetData || timesheetData.length === 0) {
                return {
                    employeeName: `${employee.firstName} ${employee.lastName}`,
                    loggedHours: {}
                };
            }

            const loggedHours = timesheetData.reduce((acc, day) => {
                const date = new Date(day.date).toISOString().split('T')[0];
                acc[date] = day.hoursLogged;
                return acc;
            }, {});

            return {
                employeeName: `${employee.firstName} ${employee.lastName}`,
                loggedHours: loggedHours
            };
            });
    
            exportToExcel(combined, month, year);
            NotificationManager.success('Timesheets exported successfully');
        } catch (error) {
            console.error("Error exporting timesheets:", error);
            NotificationManager.error("Failed to export timesheet data.");
        }
    };

    const exportToExcel = (data, month, year) => {
        const excelData = [];
        const monthIndex = months.indexOf(month);
        const dates = getDaysInMonth(year, monthIndex);

        // Header row with employee names
        const header = ['Date', ...data.map(emp => emp.employeeName)];
        excelData.push(header);

        // Rows for each date
        dates.forEach(date => {
            const row = [date];
            // data.forEach(employee => {
            //     const hours = employee.loggedHours[date] || '-';
            //     row.push(hours);
            // });
            data.forEach(employee => {
                const hours = employee.loggedHours[date];
    
                // Check if the date is a Saturday or Sunday, and mark as --WH-- (week holiday)
                const dayOfWeek = new Date(date).getDay();
                const isWeekend = dayOfWeek === 0 || dayOfWeek === 6;  // 0 is Sunday, 6 is Saturday
    
                // If it's a weekend, set as --WH-- (Week Holiday), else use logged hours
                row.push(isWeekend ? 'WH' : (hours || '-'));
            });
            excelData.push(row);
        });

        // Create and download the Excel file
        const ws = XLSX.utils.aoa_to_sheet(excelData);
         // Set custom date format for the first column (Date column)
        for (let row = 2; row <= excelData.length; row++) {  // Start from the second row (1 is the header row)
            const cell = ws[`A${row}`]; // Access each cell in the 'Date' column
            if (cell) {
                // Convert the string date to a date object and format it as dd-mm-yyyy
                const date = new Date(cell.v);
                const formattedDate = `${String(date.getDate()).padStart(2, '0')}-${String(date.getMonth() + 1).padStart(2, '0')}-${date.getFullYear()}`;
                cell.v = formattedDate;
                cell.z = 'DD-MM-YYYY';
            }
        }
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, `${month}-${year}`);
        
        const fileName = `Timesheet_${month}_${year}.xlsx`;
        XLSX.writeFile(wb, fileName);
        handleCloseDialog();
    };

    const handleCloseDialog = () => {
        setSelectedEmployees([]);
        handleClose(); 
        setError('');
        setMonth(currentMonth);
        setYear(currentYear);
    };

  return (
    <div>
        <Dialog open={open} PaperProps={{ sx: { width: '350px' }, className: 'dialog' }}>
            <DialogTitle className= 'dialogTitle'>
                Export Employees TimeSheet
                <IconButton aria-label="close" onClick={handleCloseDialog}  className='dialogCloseIcon'>
                    <CancelIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent style={{ display: 'flex', flexDirection: 'column' }}>
            <Box style={{height:'12px', paddingTop:'2px'}}>
                {error && (
                    <FormHelperText error>
                        {error}
                    </FormHelperText>
                )}
            </Box>
                <Box>
                <Grid container spacing={2} marginTop={0}>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="employee-select-label">Employees</InputLabel>
                            <Select
                                labelId="employee-select-label"
                                id="employee-select"
                                multiple
                                value={selectedEmployees}
                                label="Employee"
                                onChange={handleEmployeeChange}
                                renderValue={(selected) =>
                                    selected
                                        .map((id) => {
                                            const employee = employees.find(emp => emp._id === id);
                                            return employee ? `${employee.firstName} ${employee.lastName}` : '';
                                        })
                                        .join(", ")
                                }
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: 210,
                                            overflowY: 'auto',
                                        },
                                    },
                                }}
                            >
                                {employees.map((employee) => (
                                    <MenuItem key={employee._id} value={employee._id}>
                                        <Checkbox checked={selectedEmployees.indexOf(employee._id) > -1} />
                                        <ListItemText primary={`${employee.firstName} ${employee.lastName}`} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <InputLabel id="month-select-label">Month</InputLabel>
                            <Select
                                labelId="month-select-label"
                                id="month-select"
                                value={month}
                                label="Month"
                                onChange={handleMonthChange}
                            >
                                {months.map((month) => (
                                    <MenuItem key={month} value={month}>{month}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <InputLabel id="year-select-label">Year</InputLabel>
                            <Select
                                labelId="year-select-label"
                                id="year-select"
                                value={year}
                                label="Year"
                                onChange={handleYearChange}
                            >
                                {years.map((year) => (
                                    <MenuItem key={year} value={year}>{year}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                </Box>
            </DialogContent>
            <DialogActions className='dialogActions'>
                <Button variant="outlined" onClick={handleCloseDialog}>Cancel</Button>
                <Button variant="contained" onClick={handleExport} disabled={error}>Export</Button>
            </DialogActions>
        
        </Dialog>
    </div>
  )
}
