import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, IconButton, TextField, Button, Select, MenuItem, FormControl, InputLabel, FormControlLabel, useTheme, Alert, Checkbox, styled, Tooltip, tooltipClasses, LinearProgress } from '@mui/material';
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Cancel as CancelIcon, Add as AddIcon, Clear as ClearIcon } from '@mui/icons-material';
import { getAssetTypes, getAssetModels, createAsset, updateAsset, createAssetType, createAssetModel } from '../../services/assetsService';
import { getAllEmployeeNames, updateEmployeeAssingedAssetIds } from '../../services/employeeService';
import { NotificationManager } from "react-notifications";
import dayjs from 'dayjs';
import '../../CSS/dialogPopup.css';

function AddTypeModelDialog({ open, handleClose, handleSave, label }) {
  const [newValue, setNewValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleNewValueChange = (e) => {
    const value = e.target.value;
    setNewValue(value);
    if (!value) {
      setErrorMessage('Required');
    } else {
      setErrorMessage('');
    }
  };

  const handleAdd = async () => {
    if (!newValue) {
      setErrorMessage('Required');
      return;
    }
    const response = await handleSave(newValue);
    if(response) {
      setNewValue('');
      handleClose();
    }
    else{
      setErrorMessage('already exists');
    }
    
  };

  const handlePopUpClose =() =>{
    setErrorMessage('')
    setNewValue('')
    handleClose();
  }
  return (
    <Dialog open={open} onClose={handlePopUpClose} PaperProps={{ sx: { width: '300px', borderRadius: "15px" } }} >
      <DialogTitle>Add {label}</DialogTitle>
      <IconButton aria-label="close" onClick={handlePopUpClose} sx={{ position: 'absolute', right: 1, top: 8 }}>
        <CancelIcon />
      </IconButton>
      <DialogContent>
        <TextField
          // sx={{
          //   "& .MuiInputBase-input": {
          //     textTransform: "Capitalize",
          //   },
          // }}
          fullWidth
          label={`Enter New ${label} *`}
          value={newValue}
          onChange={handleNewValueChange}
          error={!!errorMessage}
          helperText={errorMessage}
        />
      </DialogContent>
      <DialogActions className='popup-divider' sx={{ padding: "10px" }}>
        <Button onClick={handlePopUpClose}>Cancel</Button>
        <Button onClick={handleAdd} variant="contained" disabled= {!newValue}>Add</Button>
      </DialogActions>
    </Dialog>
  );
}

export default function AddAsset({ open, handleCloseDialog, initialData , setIsAssetUpdate ,assetsList}) {
  const theme = useTheme();
  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} arrow />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.primary.main,
      // boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }));
  
  const [assetId, setAssetId] = useState('');
  const [assetType, setAssetType] = useState('');
  const [model, setModel] = useState('');
  const [purchaseDate, setPurchasedDate] = useState(null);
  const [description, setDescription] = useState('');
  const [employees, setEmployees] = useState([]);
  const [employee, setEmployee] = useState('');

  const [assetTypesList, setAssetTypesList] = useState([]);
  const [modelList, setModelList] = useState([]);
  const [loading, setLoading] = useState(false);

  const [assetIdErrorMessage, setAssetIdErrorMessage] = useState('');
  const [descriptionErrorMessage, setDescriptionErrorMessage] = useState('');
  // const assetTypes = ['Laptop', 'Monitor', 'Keyboard', 'Mouse'];
  // const models = ['DELL', 'LENOVO', 'LOGITEC', 'ACER'];

  const [openAddTypeDialog, setOpenAddTypeDialog] = useState(false);
  const [openAddModelDialog, setOpenAddModelDialog] = useState(false);

  const [openClearEmployeeDialog, setOpenClearEmployeeDialog] = useState(false);
  
  const fetchEmployees = async () => {
    try {
        const response = await getAllEmployeeNames();
        setEmployees(response);
    } catch (error) {
        console.error("Error fetching employees:", error);
    }
};

  const fetchAssetModels = async () => {
    const data = await getAssetModels();
    setModelList(data);
  }

  const fetchAssetTypes = async () => {
    const data = await getAssetTypes();
    setAssetTypesList(data);
  }

  useEffect(() => {    

    if(open){
      fetchAssetTypes();
      fetchAssetModels();
      fetchEmployees();
    }
  }, [open]);

  useEffect(() => {
    if (initialData) {
      setAssetId(initialData.assetId);
      setAssetType(initialData.type?._id);
      setModel(initialData.model?._id);
      setPurchasedDate(initialData.dateOfPurchase ? dayjs(initialData.dateOfPurchase) : null);
      setDescription(initialData.description);
      setEmployee(initialData.assignedEmployee?._id || '');
      setIsChecked(initialData?.notInUse)
    } 
  }, [initialData]);

  const handleAssetId = (e) => {
    const id = e.target.value;
    setAssetId(id);
    if (!id) {
      setAssetIdErrorMessage("required");
    } else {
      if (/^[a-zA-Z0-9]+$/.test(id)) {
        setAssetId(id);
        setAssetIdErrorMessage("");
      } else {
        setAssetIdErrorMessage("invalid id!!");
      }
    }
  };

  const handleDescription = (e) => {
    const assetDescription = e.target.value.trimStart();
    setDescription(assetDescription);
    if (!assetDescription) {
      setDescriptionErrorMessage("required");
    } else {
      setDescription(assetDescription);
      setDescriptionErrorMessage('');
    }
  };

  const handlePurchaseDate = (selectedDate) => {
    setPurchasedDate(selectedDate);
  }

  const handleAssetType = (event) => {
    setAssetType(event.target.value);
  };

  const handleAssetModel = (event) => {
    setModel(event.target.value);
  };

  const handleSave = async () => {
    if(loading)return;
    const isAssetIdDuplicate = assetsList.some(
      (asset) => asset.assetId === assetId && asset._id !== initialData?._id
    );
  
    if (isAssetIdDuplicate) {
      NotificationManager.error("An asset with this ID already exists");
      return; 
    }
  
    const newAsset = {
      assetId: assetId,
      type: assetType,
      model: model,
      description: description.trim(),
      dateOfPurchase: purchaseDate.toISOString(),
      assignedEmployee: employee == '' ? null : employee,
      notInUse: isChecked,
    }
  //   if (employee == '') {  
  //     newAsset.assignedEmployee = employee;
  // }
    try {
      setLoading(true)
      if (initialData) {
        await updateAsset(initialData._id, newAsset);
        if (initialData.assignedEmployee?._id !== employee ) {
            await updateEmployeeAssingedAssetIds(initialData.assignedEmployee?._id , employee, initialData._id);
        }
        setIsAssetUpdate((prev)=>!prev);
        NotificationManager.success("Asset Updated Succesfully");
      } else {
        const response = await createAsset(newAsset);
        if (employee) { 
        await updateEmployeeAssingedAssetIds(null, employee , response.data._id , );
        }
        setIsAssetUpdate((prev)=>!prev);
        NotificationManager.success(response.message);
      }
      handleClose();
    } catch (error) {
      console.error("error", error);
      NotificationManager.error(error.response.data.message);
    }finally{
      setLoading(false); 
    }
  };

  const handleSaveModel = async (newModel) => {
    try {
      const responseData = await createAssetModel({ model: newModel });
      fetchAssetModels();
      setModel(responseData.savedAssetModel._id);
      NotificationManager.success("Asset Model Added Successfully");
      return true;
    } catch (error) {
      console.error("error", error);
      NotificationManager.error("Failed to add an Asset Model");
      return false;
    }
  };

  const handleSaveType = async (newType) => {
    try {
      const responseData = await createAssetType({ type: newType });
      fetchAssetTypes();
      setAssetType(responseData.savedAssetType._id);
      NotificationManager.success("Asset Type Added Successfully");
      return true;
    } catch (error) {
      console.error("error", error);
      NotificationManager.error(error.response.data.message);
      return false;
    }
  };

  const handleEmployeeSelect = (event) => {
    setEmployee(event.target.value);
};

  const handleClose = () => {
    setAssetId('');
    setAssetIdErrorMessage('');
    setAssetType('');
    setModel('');
    setPurchasedDate(null);
    setDescription('');
    setEmployee('');
    setIsChecked(false);
    handleCloseDialog(false);
    setDescriptionErrorMessage('');
  };

  const confirmRemoveEmployee = async () => {
    setEmployee('');
    closeConfirmPopup();
};

const closeConfirmPopup = () => {
  setOpenClearEmployeeDialog(false);
};

const [isChecked, setIsChecked] = useState(false); 
const [isDialogOpen, setIsDialogOpen] = useState(false); 

const handleCheckboxChange = (event) => {
  setIsChecked(event.target.checked);
  if (event.target.checked) {
    if(employee){
    setIsDialogOpen(true); 
    }
  }
};

const handleDialogClose = () => {
  setIsDialogOpen(false);
  setIsChecked(false); 
};

const handleOk = () => {
  setIsDialogOpen(false); 
  confirmRemoveEmployee();
};


  return (
    <>
    <Dialog open={open} onClose={handleClose} PaperProps={{ sx: { width: '400px' },className: 'dialog' }} >
      <DialogTitle className= 'dialogTitle'>
        <strong>{initialData ? 'Update Asset' : 'Add Asset'}</strong>
        <IconButton aria-label="close" onClick={handleClose} className='dialogCloseIcon'>
          <CancelIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent  className='dc-bottom-padding'>
          <TextField
            required
            fullWidth
            id="assetId"
            label="Asset Id"
            margin="normal"
            error={assetIdErrorMessage}
            name="assetId"
            onChange={(e) => handleAssetId(e)}
            onBlur={handleAssetId}
            value={assetId}
            helperText={assetIdErrorMessage}
          />
        <FormControl fullWidth>
          <InputLabel id="assetType-select-label">Asset Type *</InputLabel>
          <div style={{ display: 'flex', alignItems: 'center' }}>
          <Select
            labelId="assetType-select-label"
            id="assetType-select"
            value={assetType}
            label="Asset Type * "
            onChange={handleAssetType}
            sx={{width:"95%"}}
          >
             {assetTypesList.map((assetType) => (
              <MenuItem value={assetType._id}>{assetType.type}</MenuItem>
            ))}
          </Select>
          <IconButton onClick={() => setOpenAddTypeDialog(true)} sx={{color:theme.palette.primary.main }}>
            <AddIcon fontSize="large" />
          </IconButton>
          </div>
        </FormControl>
        <FormControl fullWidth sx={{marginTop: '10px' }}>
          <InputLabel id="model-select-label">Model *</InputLabel>
          <div style={{ display: 'flex', alignItems: 'center'}}>
          <Select
            labelId="model-select-label"
            id="model-select"
            value={model}
            label="Model * "
            onChange={handleAssetModel}
            sx={{width:"90%"}}
          >
             {modelList.map((model) => (
              <MenuItem value={model._id}>{model.model}</MenuItem>
            ))}
          </Select>
          <IconButton onClick={() => setOpenAddModelDialog(true)}
           sx={{color:theme.palette.primary.main }} >
            <AddIcon fontSize="large"/>
          </IconButton>
          </div>
        </FormControl>
          <FormControl fullWidth sx={{ marginTop: '10px' }}>
            <InputLabel id="Assign-to"> Select Employee to Assign Asset</InputLabel>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Select
                labelId="employee"
                id="employee-select"
                value={employee}
                label="Select Employee to Assign Asset"
                onChange={handleEmployeeSelect}
                sx={{ width: "90%" }}
              >
                {employees.map((employee) => (
                  <MenuItem value={employee._id}>{employee.firstName} {employee.lastName}</MenuItem>
                ))}
              </Select>
              <LightTooltip title="Unassign Employee for this Asset" placement="left">
                <IconButton onClick={() => setOpenClearEmployeeDialog(true)}
                  sx={{ color: theme.palette.primary.main }} disabled={employee === ''} >
                  <ClearIcon fontSize="large" />
                </IconButton>
              </LightTooltip>
            </div>
          </FormControl>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            sx={{ width: '100%',marginTop: '10px'}}
            label="Date of Purchase *"
            value={purchaseDate}
            onChange={(e) => handlePurchaseDate(e)}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
        <TextField
          required
          fullWidth
          multiline
          rows={2}
          id="description"
          label="Description"
          margin="normal"
          error={descriptionErrorMessage}
          name="description"
          value={description}
          onChange={(e) => handleDescription(e)}
          onBlur={handleDescription}
          helperText={descriptionErrorMessage}
          sx={{ width: '100%',marginTop: '10px'}}
        />
          <FormControlLabel control={<><Checkbox checked={isChecked} onChange={handleCheckboxChange} /> Not in use</>}/> 
          {loading && <LinearProgress />}
      </DialogContent>
      <DialogActions className='dialogActions'>
        <Button onClick={handleClose} variant="outlined" >Cancel</Button>
        <Button onClick={handleSave} variant="contained" color="primary"
          disabled={!assetId || !description || !purchaseDate || !assetType || !model || !!assetIdErrorMessage || !!descriptionErrorMessage}>
          {initialData ? 'Update' : 'Save'}
        </Button>
      </DialogActions>
      <AddTypeModelDialog 
        open={openAddTypeDialog} 
        handleClose={() => setOpenAddTypeDialog(false)} 
        handleSave={handleSaveType} 
        label="Asset Type" 
      />
      <AddTypeModelDialog 
        open={openAddModelDialog} 
        handleClose={() => setOpenAddModelDialog(false)} 
        handleSave={handleSaveModel} 
        label="Asset Model" 
      />
    </Dialog>
      <Dialog open={openClearEmployeeDialog} onClose={closeConfirmPopup}>
        <DialogTitle className="confirmDialogTitle">
        Asset Unassignment Confirmation <IconButton aria-label="close" onClick={closeConfirmPopup}><CancelIcon /> </IconButton>
        </DialogTitle>
        <DialogContent className='confirmDialogContent'>
          <Alert severity="warning" className='confirmDialogMsg'> Are you sure you want to unassign this asset?</Alert>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeConfirmPopup} variant="outlined">Cancel</Button>
          <Button onClick={confirmRemoveEmployee} variant="contained" color="primary">OK</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isDialogOpen} onClose={handleDialogClose}>
        <DialogTitle className="confirmDialogTitle">Confirmation <IconButton aria-label="close" onClick={closeConfirmPopup}><CancelIcon /> </IconButton> </DialogTitle>
        <DialogContent className='confirmDialogContent' >
        <Alert severity="warning" className='confirmDialogMsg'> Are you sure this asset is not in use? This asset will be <br/>unassigned from the currently assigned employee </Alert></DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}  variant="outlined">
            Cancel
          </Button>
          <Button onClick={handleOk} variant="contained" >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
