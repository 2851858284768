import axiosInstance from './axiosInstance';
import { BASE_URL } from '../Constants';
import axios from 'axios';

export const fetchUserRoles = async (dropdown) => {
    try {
      const response = await axiosInstance.get(`${BASE_URL}/userRoles?fetchType=${dropdown}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching user roles:', error);
      throw error;
    }
};

export const createUserRoles = async (formData) => {
    try {
        const response = await axiosInstance.post(`${BASE_URL}/userRoles`, formData);
        return response.data;
    } catch (error) {
        console.error('Error creating user roles:', error);
        throw error;
    }
};

export const updateUserRole = async (id, formData) => {
    try {
        const response = await axiosInstance.put(`${BASE_URL}/userRoles/${id}`, formData);
        return response.data;
    } catch (error) {
        console.error('Error updating user role:', error);
        throw error;
    }
};

export const getRoleIdByName = async (roleName) => {
    try {
        const response = await axiosInstance.post(`${BASE_URL}/userRoles/getRoleId`, { "roleName":roleName});
        return response.data.roleId || null;
    } catch (error) {
        console.error('Error creating user roles:', error);
        return null;
    }
};

export const getRoleNameById = async (roleId) => {
    try {
        const response = await axiosInstance.get(`${BASE_URL}/userRoles/getRoleName/${roleId}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching role name:', error);
        throw error;
    }
};

export const checkExistsOrCreateRole = async (token, roleName , status , description) => {
    try {
        const response = await axios.post(`${BASE_URL}/userRoles/checkExistsOrCreateRole`, { "userRole":roleName , "activeStatus" :status , "description" :description },
            { headers: {
            Authorization: `Bearer ${token}` 
          }});
        return response.data.roleId;
    } catch (error) {
        console.error('Error creating user roles:', error);
        throw error;
    }
};