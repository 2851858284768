import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Button, Grid, LinearProgress, useTheme } from '@mui/material';
import { format, parseISO } from 'date-fns';
import { BorderColor as BorderColorIcon, Add as AddIcon, PlaylistAddCheck as PlaylistAddCheckIcon } from '@mui/icons-material';
import { getAssetsList } from '../../services/assetsService';
import AddAsset from './AddAsset';
import AssignAsset from './AssignAsset';
import { PAGE_SIZE_OPTIONS, ROWS_PER_PAGE } from '../../Constants';

export default function AssetManagement() {
    const theme = useTheme();

    const [popupOpen, setPopupOpen] = useState(false);
    const [assignPopupOpen, setAssignPopupOpen] = useState(false);
    const [assetsList, setAssetsList] = useState([]);
    const [selectedAsset, setSelectedAsset] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isAssetUpdate , setIsAssetUpdate] = useState(false);

    const handleAssignClick = () => {
        setAssignPopupOpen(true);
    };
    const handleCloseAssignPopup = () => {
        setAssignPopupOpen(false);
    };
    const handleAddClick = () => {
        setSelectedAsset(null);
        setPopupOpen(true);
    };

    const handleEditClick = (asset) => {
        setSelectedAsset(asset);
        setPopupOpen(true);
    };

    const handleClosePopup = () => {
        setPopupOpen(false);
    };

    const formatDate = (isoDateString) => {
        if (!isoDateString) return '';
        const date = parseISO(isoDateString);
        return format(date, 'dd-MM-yyyy');
      };
    
    const columns = [
        { field: 'assetId', headerName: <strong>Asset ID</strong>, width: 100 },
        { field: 'type', headerName: <strong>Type</strong>, width: 150,
            valueGetter: (params) => params?.type
         },
        { field: 'model', headerName: <strong>Model</strong>, width: 150,
            valueGetter: (params) => params?.model
         },
        { field: 'dateOfPurchase', headerName: <strong>Purchased Date</strong>, width: 200,
            valueGetter: (params) => formatDate(params),
        },
        { field: 'description', headerName: <strong>Description</strong>, width: 250 },
        { field: 'assignedEmployee', headerName: <strong>Assigned Employee</strong>, width: 150,
            valueGetter: (params) => {
                return params ? `${params.firstName || ''} ${params.lastName || ''}`  : '-----';
              }
         },
        {
            field: 'actions',
            headerName: <strong>Actions</strong>,
            width: 250,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => (
                <BorderColorIcon onClick={() => handleEditClick(params.row)}/>
            )
        }
    ];

    const fetchAssetData = async () => {
        try {
          const response = await getAssetsList();
          setAssetsList(response);
          setLoading(false);
        } catch (error) {
          console.error('Error fetching Asset List:', error);
          setLoading(false);
        }
      }

    useEffect(() => {
        fetchAssetData();
      }, [selectedAsset ,isAssetUpdate]);
    return (
        <Box className = 'main-content' >
                
                    <Grid container className='grid-container-header align-header-center'>
                    <Grid item xs={6}>                        
                        Asset Management
                    </Grid>
                    <Grid item xs={6} className='align-filters-right'>
                        <Button variant="contained" onClick={handleAssignClick} startIcon={<PlaylistAddCheckIcon />}>
                            Manage
                        </Button>
                        <Button variant="contained" onClick={handleAddClick} startIcon={<AddIcon />}>
                            Add
                        </Button>
                    </Grid>
                 </Grid>  
                
                <Grid container padding={2} style={{ backgroundColor: '#fff' }}>
                    <Grid item xs={12} sx={{ height: "375px" }}>
                        <DataGrid
                            localeText={{ noRowsLabel: "No Assets available. Please add a new Asset." }}
                            rows={assetsList}
                            columns={columns}
                            initialState={{ pagination: { paginationModel: { pageSize: ROWS_PER_PAGE } } }}
                            pagination
                            pageSizeOptions={PAGE_SIZE_OPTIONS}
                            loading={loading}
                            getRowId={(row) => row._id}
                            components={{
                                LoadingOverlay: LinearProgress,
                            }}
                            sx={{
                                '& .MuiDataGrid-selectedRowCount': {
                                display: 'none',
                                },
                            }}
                        />
                    </Grid>
                </Grid>
                <AddAsset open={popupOpen} handleCloseDialog={handleClosePopup} initialData={selectedAsset} setIsAssetUpdate={setIsAssetUpdate} assetsList={assetsList} />
                <AssignAsset open={assignPopupOpen} handleCloseDialog={handleCloseAssignPopup} setIsAssetUpdate={setIsAssetUpdate} assetsList={assetsList}/>
        </Box>
    );
}
