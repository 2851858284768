import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, Tooltip, IconButton, useTheme } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { BorderColorSharp as BorderColorSharpIcon, DashboardCustomize as DashboardCustomizeIcon} from '@mui/icons-material';
import { SvgIcons } from '../../assets/icons/SvgIcons';
import StatusSwitch from '../../assets/icons/StatusSwitch';
import { getModules } from '../../services/ModuleServices';
import ModulePopup from './ModulePopup';
import { PAGE_SIZE_OPTIONS, ROWS_PER_PAGE, STATUS } from '../../Constants';

function Modules() {
  const theme  = useTheme();
  const [modules, setModules] = useState([]);
  const [openDataHandle, setOpenDataHandle] = useState(false);
  const [selectedModule, setSelectedModule] = useState(null);
  const [isUpdate, setIsUpdate] = useState(false);
  const [loading, setLoading] = useState(true);
  const [statusSort, setStatusSort] = useState(true);

  useEffect(() => {
    fetchModuleList();
  }, []);

  const fetchModuleList = async () => {
    try {
      const data = await getModules();
      setModules(data);
    } catch (error) {
      console.error("Error in fetching the data", error);
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      field: "name",
      headerName: (
        <Tooltip title="Name" arrow>
          <strong>Name</strong>
        </Tooltip>
      ),
      width: 200,
    },
    {
      field: "description",
      headerName: (
        <Tooltip title="Description" arrow>
          <strong>Description</strong>
        </Tooltip>
      ),
      width: 650,
    },
    {
      field: "activeStatus",
      headerName: (
        <Tooltip title="Status" arrow>
          <strong>Status</strong>
        </Tooltip>
      ),
      width: 150,
      renderCell: (params) => (
        <span>
          {params.value ? (
            <SvgIcons marginTop="8px" fillColor={STATUS.ACTIVE_COLOR} text={STATUS.ACTIVE} />
          ) : (
            <SvgIcons marginTop="8px" fillColor={STATUS.INACTIVE_COLOR} text={STATUS.INACTIVE} />
          )}
        </span>
      ),
    },
    {
      field: "edit",
      headerName: (
        <Tooltip title="Action" arrow>
          <strong>Action</strong>
        </Tooltip>
      ),
      width: 100,
      renderCell: (params) => {
        return (
          <Tooltip title="Edit" arrow>
            <IconButton onClick={() => handleEditModule(params.row)}>
              <BorderColorSharpIcon />
            </IconButton>
          </Tooltip>
        );
      },
    },
  ];

  const handleAddModule = () => {
    setIsUpdate(false);
    setOpenDataHandle(true);
    setSelectedModule(null);
  };

  const handleEditModule = (module) => {
    setSelectedModule(module);
    setIsUpdate(true);
    setOpenDataHandle(true);
  };

  const handleCloseDataHandle = () => {
    setOpenDataHandle(false);
    setSelectedModule(null);
    // fetchModuleList();
  };

  const filteredModules = modules.filter((module) => module.activeStatus === statusSort);
  
  return (
      <Box className = 'main-content'>          
            <Grid container className='grid-container-header align-header-center'>
              <Grid item xs={3}>
                  App Modules
              </Grid> 
            <Grid item xs={9} className='align-filters-right'>
              <StatusSwitch
                checked={statusSort}
                onChange={(e) => setStatusSort(e.target.checked)}
              />
              <Button variant="contained" onClick={handleAddModule} startIcon={<DashboardCustomizeIcon/>} 
              sx={{
                whiteSpace: 'nowrap'
              }}>
                Add Module
              </Button>
            </Grid>
            </Grid>
          
          <Grid container padding={2} sx={{ backgroundColor: '#fff' }}>
            <Grid item xs={12} sx={{ height: "375px" }}>
              <DataGrid
                localeText={{ noRowsLabel: "No user modules available. Please add a new user module." }}
                rows={filteredModules}
                columns={columns}
                initialState={{ pagination: { paginationModel: { pageSize: ROWS_PER_PAGE } } }}
                pageSizeOptions={PAGE_SIZE_OPTIONS}
                pagination
                loading={loading}
                components={{
                  LoadingOverlay: () => <div>Loading...</div>
                }}
                getRowId={(row) => row._id}
                sx={{
                  '& .MuiDataGrid-selectedRowCount': {
                    display: 'none',
                  },
                }}
              />
            </Grid>
          </Grid>
      <ModulePopup
        open={openDataHandle}
        handleClose={handleCloseDataHandle}
        module={selectedModule}
        refreshData={fetchModuleList}
        isUpdate={isUpdate}
        modules={modules}
      />
      </Box>
  );
}

export default Modules;
