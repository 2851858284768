import { createContext, useState } from 'react';

// Create the context
const ProfileImageContext = createContext();

// Create the provider component
export const ProfileImageProvider = ({ children }) => {
  const [profileImage, setProfileImage] = useState();
  
  return (
    <ProfileImageContext.Provider value={{ profileImage, setProfileImage }}>
      {children}
    </ProfileImageContext.Provider>
  );
};

export default ProfileImageContext;
