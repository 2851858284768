import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main:  "#7F141A", /*"#cc3333",*/
    },
    secondary: {
      main: '#F6F8FC' /*"#FAEAEA",*/
    },
    border: "#E4E4E4",
    iconColor: "#4F535A",
  },
  typography: {
    fontSize: 12,
    fontFamily: ["Lato", "sans-serif"].join(","),
    color: "#767C87",
    h1: {
      fontSize: "2rem",
      fontWeight: 600,
      lineHeight: 1.25,
    },
    h2: {
      fontSize: "1.75rem",
      fontWeight: 600,
      lineHeight: 1.25,
    },
    h3: {
      fontSize: "1.5rem",
      fontWeight: 700,
      lineHeight: "19.2px",
    },
    h4: {
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: 1.25,
      color: "#292D32",
    },
    h5: {
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: 1.25,
      color: "#767C87",
    },
    label: {
      fontSize: "14px",
      fontWeight: "700",
      color: "#292d32",
    },
    span: {
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "20px",
    },
    h6: {
      fontSize: "1rem",
      fontWeight: 500,
      lineHeight: 1.25,
    },
    subtitle1: {
      fontSize: 13,
    },
    subtitle2: {
      fontSize: 13,
    },
    body2: {
      fontSize: 13,
      color: "#767C87",
    },
    error: {
      fontSize: "0.7rem",
      fontWeight: "400",
      color: "#cc3333",
    },
    button: {
      textTransform: "none",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "4px",
          fontSize: "14px",
          fontWeight: "400",
          maxHeight: "36px",
          padding: "8px 20px",
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
          },
          "&.Mui-disabled": {
            color: "#ffffff",
            backgroundColor: "#FAEAEA",
          },

          "&.MuiButton-contained.MuiButton-containedPrimary": {
            color: "#ffffff",
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            padding: "0px",
          },
          "& fieldset": {
          },
          "& input": {
            fontSize: "12px",
            fontWeight: "500",
            color: "#141518",
            width: "100%",
            borderRadius: "4px",
          },
          "& textarea": {
            width: "100%",
            fontSize: "12px",
            fontWeight: "500",
            color: "#141518",
            padding: "10px 14px",
            backgroundColor: "#FFFFFF",
            borderRadius: "4px",
          },
          "& input::placeholder": {
            fontSize: "12px",
            fontWeight: "500",
            color: "#767c87",
          },
        },
      },
    },
   
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          "& .MuiFormControlLabel-label": {
            fontSize: "16px",
            fontWeight: 600,
            lineHeight: 1.25,
            color: "#292D32",
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          "&.Mui-selected ": {
            color: "#1C96F2",
          },
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          border: "none",
          borderTop: "1px solid #e4e4e4",
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          width: "100%",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: "12px",
          fontWeight: "400",
          color: "#000000",
          padding: "9px",
          minHeight: "auto",

          "&.Mui-selected": {
            fontSize: "12px",
            fontWeight: "700",
            color: "#cc3333",
            borderRadius: "4px",
          },
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: "none",
          minHeight: "300px",
          overflowY: "auto",
          margin: "0 0 0 8px",
          "&. MuiCheckbox-root": {
            backgroundColor: "#ff0000",
            "& .MuiSvgIcon-root": {
              color: "#E4E4E4",
            },
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: "0.9em",
          padding: "0.8em",
          borderRadius: "1em",
          lineHeight: "1.5em",
          backgroundColor: "#919191"
        }
      }
    }
  },
});

document.documentElement.style.setProperty('--primary-main', theme.palette.primary.main);
document.documentElement.style.setProperty('--secondary-main', theme.palette.secondary.main);

export default theme;

