import React from 'react';
import { Box, Button, Typography, Container } from '@mui/material';
import './loginDisabled.css';

const LoginDisabled = () => (
  <Container maxWidth="xs" className="loginDisabled-container" sx={{ mt: 5 }}>
    <Box className="loginDisabled-box">
      <Typography variant="h4" component="h1" className="loginDisabled-title">
        Login Disabled
      </Typography>
      <Typography variant="body1" className="loginDisabled-message">
        Your login is currently disabled. Please contact the admin for assistance.
      </Typography>
      <Box className="loginDisabled-button-container">
        <Button
          variant="contained"
          color="primary"
          className="loginDisabled-button"
        >
          Contact Administrator
        </Button>
      </Box>
    </Box>
  </Container>
);

export default LoginDisabled;
