import React from "react";

export const SvgIcons = ({ marginTop, fillColor, text }) => {
    return (
      <div style={{ marginTop }}>
          <svg width="81" height="32" viewBox="0 0 53 14">
              <rect width="53" height="16" x="0" y="0" rx="7" ry="7" fill={ fillColor } />
              <text x="50%" y="60%" fill="white" fontSize="8" fontWeight="bold" dominantBaseline="middle" textAnchor="middle">
                { text }
              </text>
          </svg>
      </div>
    );
};