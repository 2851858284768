
import React, { useEffect, useRef, useState } from 'react';
import { Alert, Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, IconButton, InputLabel, MenuItem, Select, Tab, Tabs, Typography, useTheme, Accordion, AccordionDetails, AccordionSummary, LinearProgress  } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { NotificationManager } from "react-notifications";
import { Cancel as CancelIcon, Delete as DeleteIcon, ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { getAssetsList, getAssetTypes, updateAsset } from '../../services/assetsService';
import { updateEmployee, removeAsset, getAssetsByEmployee, getEmployeesForAssets } from '../../services/employeeService';
import './assignAsset.css';
import '../../CSS/dialogPopup.css';


export default function AssignAsset({ open, handleCloseDialog ,setIsAssetUpdate, assetsList}) {
    const theme = useTheme();
    const [employees, setEmployees] = useState([]);
    const [assetTypes, setAssetTypes] = useState([]);
    const [value, setValue] = useState(0);
    // the types append with all option to sort datagrid rows 
    const [typesList, setTypesList] = useState([]);
    const [filteredAssets, setFilteredAssets] = useState({});
    const [originalAssignedAssets, setOriginalAssignedAssets] = useState([]);

    const [assignedAssetsData , setAssignedAssetsData] = useState([]);
    const [assignedAssets, setAssignedAssets] = useState([]);
    const [expandedAccordion, setExpandedAccordion] = useState(false);

    const [employee, setEmployee] = useState('');
    const [employeeErrormsg, setEmployeeErrormsg] = useState('');
    const employeeRef = useRef(null);

    const [tabValue, setTabValue] = useState(0); 

    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [assetToRemove, setAssetToRemove] = useState(null);
    const [loading, setLoading] = useState(false);

    // const assetTypes = ['Laptop', 'Monitor', 'Keyboard', 'Mouse'];
    const fetchAssetTypes = async () => {
        const data = await getAssetTypes();
        const assetTypesFromResponse = data.map(assetTypes => assetTypes.type);
        setAssetTypes([...new Set(assetTypesFromResponse)]); 
        const types = ['All', ...new Set(data.map((item) => item.type))];
        setTypesList(types);

      }

    const handleEmployeeSelect = (event) => {        
        setEmployee(event.target.value);
        setEmployeeErrormsg("");
        setValue(0);  
    };

    const handleClose = () => {
        setExpandedAccordion(false);
        setEmployee('');
        setAssignedAssets([]);
        setOriginalAssignedAssets([]);
        setEmployeeErrormsg("");
        handleCloseDialog(false);
    };

    const handleSave = async () => {
       if(loading) return;
        const assignedAssetsIds = assignedAssets.map(asset => asset._id);
        const originalAssignedAssetsIds = originalAssignedAssets.map(asset => asset._id);
        const unassignedAssetsIds = originalAssignedAssetsIds.filter(id => !assignedAssetsIds.includes(id));
        const assignedAssetData =
        {
            assignedAssetsIds: assignedAssetsIds,
        }
        try {
            setLoading(true);
            await updateEmployee(employee, assignedAssetData);
            // update assigned employees in asset
            await Promise.all(assignedAssetsIds.map(assetId => updateAsset(assetId, {assignedEmployee : employee})));
            // remove assigned employees in asset
            await Promise.all(unassignedAssetsIds.map(assetId => 
                updateAsset(assetId, { assignedEmployee: null })
            ));
            NotificationManager.success("Assets Assigned successfully");
            setIsAssetUpdate((prev)=> !prev);
            handleClose();
        } catch (error) {
            console.error(error);
            NotificationManager.error('Some error occured');
        }finally{
            setLoading(false); 
          }
        // handleCloseDialog(false);
    };

    const handleAccordionChange = (panel) => (event, isExpanded) => {
        setExpandedAccordion(isExpanded ? panel : false);
        if (isExpanded) {
            const filtered = assetsList.filter(asset => asset?.type?.type === panel);
            setFilteredAssets(prev => ({ ...prev, [panel]: filtered }));
        }
    };

    const fetchEmployees = async () => {
        try {
            const response = await getEmployeesForAssets();
            setEmployees(response);
        } catch (error) {
            console.error("Error fetching employees:", error);
        }
    };


    const fetchAssetsByEmployee = async () => {
        if (employee) {
            const assignedEmployee = await getAssetsByEmployee(employee);
            if (assignedEmployee) {                
                setAssignedAssets(assignedEmployee.assignedAssetsIds);
                setOriginalAssignedAssets(assignedEmployee.assignedAssetsIds);
                setAssignedAssetsData(assignedEmployee.assignedAssetsIds);
            }            
        }
    }


    useEffect( () => {        
        fetchAssetsByEmployee();   
    }, [employee]);

    useEffect(() => {
      
        if(open){
            fetchAssetTypes();
            fetchEmployees();   
        }
    }, [open]);

    const handleCheckboxChange = (assetId) => {
        if(employee){
            setEmployeeErrormsg("");
            setAssignedAssets((prev) => {
                // Check if the asset is already assigned
                const isAssigned = prev.some(asset => asset.assetId === assetId);
                if (isAssigned) {
                    // If it is assigned, remove it
                    return prev.filter(asset => asset.assetId !== assetId);
                } else {
                    // If it is not assigned, add it
                    const selectedAsset = assetsList.find(asset => asset.assetId === assetId);
                    return [...prev, selectedAsset];
                }
            });
        } else {
            // if (employeeRef.current) {
                employeeRef.current.focus(); // Focus the Select component
            // }
            setEmployeeErrormsg("Please select the Employee");
        }
       
    };

    const areAssetsSame = () => {
        if (assignedAssets.length !== originalAssignedAssets.length) return false;
        const assignedAssetIds = assignedAssets.map(asset => asset.assetId);
        const originalAssetIds = originalAssignedAssets.map(asset => asset.assetId);
        return assignedAssetIds.sort().join(',') === originalAssetIds.sort().join(',');
    };

  const handleChange = (event, newValue) => {
    setTabValue(newValue); 
  };

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}
      >
        {value === index && (
          <Typography component="div" sx={{ p: 3 }}>
            {children}
          </Typography>
        )}
      </div>
    );
  };
  const columns = [
    { field: 'assetId', headerName: 'Asset ID', width: 150 },
    { field: 'type', headerName: 'Type', width: 150, valueGetter: (params) => params.type },
    { field: 'model', headerName: 'Model', width: 450, valueGetter: (params) => params.model },
    {
        field: 'actions',
        headerName: 'Actions',
        width: 150,
        renderCell: (params) => (
            <IconButton onClick={() => handleRemoveAsset(params.row)}>
                <DeleteIcon color="error" />
            </IconButton>
        ),
    },
];

const handleTabChange = (event, newValue) => {
    setValue(newValue);
    const selectedType = typesList[newValue];
     if (selectedType === 'All') {
        setAssignedAssetsData(originalAssignedAssets);
    } else {
      const filtered = originalAssignedAssets.filter((item) => item.type.type === selectedType);
      setAssignedAssetsData(filtered); 
    }
  };

  const handleRemoveAsset = (asset) => {
    setAssetToRemove(asset); 
    setConfirmDialogOpen(true); 
}; 

const confirmRemove = async () => {

    const updatedAssetsData = assignedAssetsData.filter(a => a.assetId !== assetToRemove.assetId);
    const updatedAssignedAssets = assignedAssets.filter(a => a.assetId !== assetToRemove.assetId);
    setAssignedAssetsData(updatedAssetsData);

    const updatedData =
    {
        assignedId: assetToRemove._id,
    }
    try {
        await removeAsset(employee, updatedData);
        setAssignedAssets(updatedAssignedAssets);
        setOriginalAssignedAssets(updatedAssignedAssets);
        NotificationManager.success("Assets removed successfully");
        setIsAssetUpdate((prev) => !prev);
        cancelRemove();
    } catch (error) {
        NotificationManager.error('Some error occured');
    }
};

const cancelRemove = () => {
    setConfirmDialogOpen(false); 
    setAssetToRemove(null);
};

    return (
        <>
        <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="xl"  PaperProps={{  sx: { width: '90vw', height: "98vh" }, className: 'dialog', }}  >
            <DialogTitle className= 'dialogTitle'>
                <strong>Manage Asset</strong>
                <IconButton aria-label="close" onClick={handleClose} className='dialogCloseIcon' >
                    <CancelIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent  className='dc-bottom-padding'>
                <br />
                <FormControl sx={{ width: "100%" }}>
                    <InputLabel id="employee-select-label"> Select Employee</InputLabel>
                    <Select
                        labelId="employee-select-label"
                        id="employee-select"
                        value={employee}
                        label="Select Employee"
                        onChange={handleEmployeeSelect}
                        inputRef={employeeRef}
                    >
                        {employees.map((employee) => (
                            <MenuItem value={employee._id}>{employee.firstName} {employee.lastName}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {/* {employeeErrormsg.length>0 && <p style={{color:"#ff0000", display : "flex", fontSize:".9rem"}}><ErrorOutlineIcon/>&nbsp;{employeeErrormsg}</p> } */}
                {employeeErrormsg.length>0 && <Alert severity="error"  sx={{ backgroundColor: "transparent"}}>{employeeErrormsg}</Alert> }
                <Box sx={{ display: 'flex', marginTop: '20px' }}>
                    <Box sx={{height:"120px"}} >
                    <Tabs orientation="vertical" defaultValue={0} value={tabValue} onChange={handleChange}
                        TabIndicatorProps={{ style: { display: 'none' } }}
                        sx={{
                            minWidth: '200px',
                            '& .MuiTab-root': {
                                backgroundColor: "#F8F8F8",
                                color: theme.palette.primary.main,
                                 padding:'15px',
                                // borderRadius: '20px',
                                '&.Mui-selected': {
                                    backgroundColor: theme.palette.primary.main,
                                    color: '#fff',
                                    // border: `1px solid ${theme.palette.primary.main}`,
                                    // borderRadius: '20px',
                                },
                            },
                        }}
                    >
                        <Tab label="Assign Assets" sx={{ width: "100%" , margin:"0px"}} />
                        <Tab label="Assigned Assets" sx={{ width: "100%" }} />
                    </Tabs>
                    </Box>
                    <Box sx={{ flexGrow: 1,backgroundColor: "#F8F8F8" }}>
                        <TabPanel value={tabValue} index={0} >
                            {assetTypes.map((type) => (
                                <Accordion
                                    key={type}
                                    expanded={expandedAccordion === type}
                                    onChange={handleAccordionChange(type)}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls={`panel-${type}-content`}
                                        id={`panel-${type}-header`}
                                    >
                                        <Typography>{type}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails
                                        sx={{ marginTop: "-20px", maxHeight: "110px", overflow: "auto", }}>
                                        {filteredAssets[type] && filteredAssets[type].length > 0 ? (
                                            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                                                {filteredAssets[type]
                                                    .sort((a, b) => {
                                                        const isAssignedA = employees.some(emp =>
                                                            emp.assignedAssetsIds?.some(assignedAsset => assignedAsset.includes(a._id)) && emp._id !== employee
                                                        );
                                                        const isAssignedB = employees.some(emp =>
                                                            emp.assignedAssetsIds?.some(assignedAsset => assignedAsset.includes(b._id)) && emp._id !== employee
                                                        );
                                                        return isAssignedA - isAssignedB; // Sort enabled (false) before disabled (true)
                                                    })
                                                    .map(asset => {
                                                        const isAssignedToAnotherEmployee = employees.some(emp =>
                                                            emp.assignedAssetsIds?.some(a => a.includes(asset._id)) && emp._id !== employee
                                                        );
                                                        return (
                                                            <div key={asset._id} style={{ flex: '0 1 15%' }}>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            // checked={selectedAssets.some(item => item._id === asset._id)}
                                                                            checked={assignedAssets.some(a => a.assetId === asset.assetId)}
                                                                            disabled={isAssignedToAnotherEmployee} // Disable if assigned to another employee
                                                                            onChange={() => handleCheckboxChange(asset.assetId)}
                                                                             />
                                                                    }
                                                                    label={`${asset.assetId} - ${asset.model.model}`}
                                                                    sx={{
                                                                        '& .MuiFormControlLabel-label': {
                                                                            fontSize: '0.6rem',
                                                                        },
                                                                        marginBottom: '-25px', marginTop: '-20px',
                                                                    }}
                                                                />
                                                            </div>
                                                        )
                                                    }
                                                    )}
                                            </div>
                                        ) : (
                                            <Typography>No assets available for this type.</Typography>
                                        )}
                                    </AccordionDetails>
                                </Accordion>
                            ))}
                            {/* </div> */}
                        </TabPanel>
                        <TabPanel value={tabValue} index={1}>
                            {employee ? (
                                <>
                                    {assignedAssets.length > 0 ? (
                                        <Box sx={{ width: '95%' }}>
                                            <Tabs value={value} onChange={handleTabChange}>
                                                {typesList.map((type, index) => (
                                                    <Tab key={index} label={type} sx={{ width: "50px" }} />
                                                ))}
                                            </Tabs>

                                            <Box sx={{ minheight: 300, width: '100%', marginTop: 2 }}>
                                                <DataGrid
                                                    localeText={{ noRowsLabel: "No Assets Assigned to this Employee" }}
                                                    rows={assignedAssetsData}
                                                    columns={columns}
                                                    getRowId={(row) => row._id}
                                                    pageSize={5}
                                                    rowsPerPageOptions={[5]}
                                                />
                                            </Box>
                                        </Box>
                                    ) : (
                                        <>
                                            <br />
                                            <Alert severity="warning"  sx={{ backgroundColor: "transparent" , fontSize:"1rem"}}>No Assets Assigned to this Employee</Alert>
                                        </>
                                    )
                                    }
                                </>
                            ) : (
                                <>
                                    <br />
                                    {/* <Typography variant="h5">Please Select the Employee</Typography> */}
                                    <Alert severity="warning"  sx={{ backgroundColor: "transparent" , fontSize:"1rem"}}>Please Select the Employee</Alert>
                                </>
                            )
                            }
                        </TabPanel>
                    </Box>
                </Box>
                {loading && <LinearProgress/>}
            </DialogContent>
            <DialogActions className='dialogActions'>
                <Button onClick={handleClose} variant="outlined" >Cancel</Button>
                <Button onClick={handleSave} variant="contained" color="primary" disabled={!employee || areAssetsSame()}> Save </Button>
            </DialogActions>
        </Dialog>
        {/* Confirmation popup */}
            <Dialog open={confirmDialogOpen} onClose={cancelRemove}>
                <DialogTitle className="confirmDialogTitle">
                Asset Unassignment Confirmation <IconButton aria-label="close" onClick={cancelRemove}><CancelIcon /></IconButton>
                </DialogTitle>
                <DialogContent className='confirmDialogContent'>
                    <Alert severity="warning" className='confirmDialogMsg'> Are you sure you want to unassign this asset?</Alert>
                </DialogContent>
                <DialogActions>
                    <Button onClick={cancelRemove} variant="outlined">Cancel</Button>
                    <Button onClick={confirmRemove} variant="contained" color="primary">OK</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
