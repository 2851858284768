import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, IconButton, FormControl, InputLabel, Select, MenuItem, Button } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { NotificationManager } from 'react-notifications';
import { getEmployeesByRole, updateEmployee } from '../../services/employeeService';

export default function EditReportingTo({ isReportingToClicked, handleCloseReportingToDialog, selectedEmployee, initialReportingTo }) {

  const [reportingRoles, setReportingRoles] = useState([]);
  const [reportingTo, setReportingTo] = useState('');

  useEffect(() => {
    if (isReportingToClicked) {
      getReportingToList();
    }
  }, [isReportingToClicked]);

  const getReportingToList = async () => {
    try {
      const data = await getEmployeesByRole();
      setReportingRoles(data);
      setReportingTo(initialReportingTo);
    } catch (error) {
      console.error('Failed to fetch reporting roles:', error);
    }
  };

  const handleReportingToChange = (event) => {
    setReportingTo(event.target.value);
  };

  const handleReportingToSave = async () => {
    try {
        const updatedData = { reportingTo };
  
        await updateEmployee(selectedEmployee, updatedData);
        NotificationManager.success("Reporting Manager updated successfully");
        handleReportingToPopupClose();
      } catch (error) {
        console.error("Error:", error);
        NotificationManager.error("An error occurred while updating Reporting Manager");
    }
  };

  const handleReportingToPopupClose = () => {
    handleCloseReportingToDialog(false);
  };

  return (
    <div>
      <Dialog
        open={isReportingToClicked}
        onClose={handleReportingToPopupClose}
        PaperProps={{ sx: { width: '350px' }, className: 'dialog' }}
      >
        <DialogTitle className="dialogTitle">
          Reporting To
          <IconButton
            aria-label="close"
            className="dialogCloseIcon"
            onClick={handleReportingToPopupClose}
          >
            <CancelIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div style={{ display: 'flex', marginTop: '20px', alignItems: 'center' }}>
            <FormControl sx={{ width: '100%' }}>
              <InputLabel id="reporting-select-label">Reporting To</InputLabel>
              <Select
                labelId="reporting-select-label"
                id="reporting-select"
                value={reportingTo}
                label="Reporting To"
                onChange={handleReportingToChange}
              >
                {reportingRoles.map((role) => (
                  <MenuItem key={role._id} value={role._id}>
                    {role.firstName} {role.lastName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </DialogContent>
        <DialogActions className="dialogActions">
          <Button variant="outlined" onClick={handleReportingToPopupClose}>
            Cancel
          </Button>
          <Button variant="contained" onClick={handleReportingToSave}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
