import axiosInstance from './axiosInstance';
import { BASE_URL } from '../Constants';

export const saveRegime = async (employeeId, regime, financialYear,oldRegimeDetails ) => {
    try {
        const formData = { employeeId, regime, financialYear,oldRegimeDetails };
        const response = await axiosInstance.post(`${BASE_URL}/itdeclaration`, formData);
        return response.data;
    } catch (error) {
        console.error('Error saving IT Declaration:', error);
        throw error;
    }
};

export const checkRegimeExistence = async (employeeId) => {
    try {
        const response = await axiosInstance.get(`${BASE_URL}/itdeclaration/${employeeId}`);
        return response.data; 
    } catch (error) {
        return undefined;
    }
};